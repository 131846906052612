import List from 'assets/list.svg?react';
import Plus from 'assets/plus.svg?react';
import Grid from 'assets/tile-view.svg?react';
import useAuth from 'contexts/auth/authContext';
import useScroll from 'hooks/useScroll';
import Button from 'modules/common/Button';
import RadioIconButtons from 'modules/common/RadioIconButtons';
import SearchParamButton from 'modules/common/SearchParamButton';
import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { IconButton, SearchParamRoute } from 'utils/interfaces';
import { reportTypeRoutes } from '../constants';
import { useQueryClient } from '@tanstack/react-query';
import { ProjectGetSchema } from 'lib/model';
import { useGetProjectProjectIdDataTable } from 'lib/data-table/data-table';
import { useGetProjectProjectIdDashboardDatasourcesCount } from 'lib/dashboard/dashboard';
import useActiveProject from 'contexts/project/projectContext';

interface ReportsPageHeaderProps {
  display: string;
  setDisplay: React.Dispatch<React.SetStateAction<string>>;
}

const ReportsPageHeader = memo(function ReportsPage({ display, setDisplay }: ReportsPageHeaderProps) {
  const queryClient = useQueryClient();
  const [expanded, setExapned] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isViewer } = useAuth();
  const { direction, y } = useScroll();

  const handleNewReport = useCallback(
    function handleNewReport() {
      navigate('/reports/new');
    },
    [navigate]
  );

  const { project } = useActiveProject();
  const { data: dataTables, isPending: isDataTablesPending } = useGetProjectProjectIdDataTable(project.id!, {
    query: {
      queryKey: ['project', project.id, 'data-tables'],
      staleTime: Infinity
    }
  });

  const { data: dataSourcesCount, isPending: isDataSourceCountPending } =
    useGetProjectProjectIdDashboardDatasourcesCount(project.id!, {
      query: { queryKey: ['project', project.id, 'data-sources', 'count'], enabled: !!project.id, staleTime: Infinity }
    });

  const buttonDisabled =
    isDataTablesPending || isDataSourceCountPending || dataSourcesCount?.count === 0 || dataTables?.dummy_data;

  useEffect(() => {
    if (y <= 50) {
      setExapned(false);
    } else if (direction === 'down') {
      setExapned(false);
    } else if (direction === 'up') {
      setExapned(true);
    }
  }, [direction, y]);

  return (
    <header className="sticky top-0 z-20 flex w-full flex-col bg-gray-100">
      <span className="pb-8 pt-10 text-lg font-semibold text-gray-950">Reports</span>
      <div className={twJoin('flex flex-col', expanded && 'divide-y divide-gray-200')}>
        <div
          className={twJoin(
            'flex w-full flex-wrap justify-between gap-6 rounded-t-xl bg-white p-4',
            !expanded && 'rounded-b-xl'
          )}
        >
          <div className="flex flex-wrap items-center gap-2">
            {ownershipRoutes.map((route) => (
              <SearchParamButton key={route.value} route={route} size="xs" />
            ))}
          </div>
          <div className="flex items-center gap-6">
            <RadioIconButtons selected={display} setSelected={setDisplay} options={displayButtons} />
            {!isViewer && (
              <Button isFullWidth={false} onClick={handleNewReport} disabled={buttonDisabled}>
                <Plus width={18} height={18} />
                <span>New Report</span>
              </Button>
            )}
          </div>
        </div>
        <div
          className={twJoin(
            'absolute top-[170px] flex w-full flex-wrap items-center gap-2 overflow-hidden rounded-b-xl bg-white px-4 transition-all',
            expanded ? 'h-[75px] shadow-card duration-300' : 'h-0 duration-100'
          )}
        >
          {reportTypeRoutes.map((route) => (
            <SearchParamButton size="tiny" key={route.value} route={route} />
          ))}
        </div>
      </div>
    </header>
  );
});

const displayButtons: IconButton[] = [
  {
    Icon: List,
    id: 'list'
  },
  {
    Icon: Grid,
    id: 'grid'
  }
];

const ownershipRoutes: SearchParamRoute[] = [
  {
    name: 'ALL',
    key: 'ownership',
    value: null
  },
  {
    name: 'MY REPORTS',
    key: 'ownership',
    value: 'my_reports'
  },
  {
    name: 'SHARED WITH ME',
    key: 'ownership',
    value: 'shared_with_me'
  }
];

export default ReportsPageHeader;
