import { keepPreviousData, useQueryClient } from '@tanstack/react-query';
import { useGetProjectProjectIdDataTableDataTableIdData } from 'lib/client-data/client-data';
import { DataTablesSchema, ProjectSchema } from 'lib/model';
import { memo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import DataTable from './components/DataTable';
import DataTableNavigation from './components/DataTableNavigation';
import useActiveProject from 'contexts/project/projectContext';

const DataTablesPage = memo(function DataTablesPage() {
  const queryClient = useQueryClient();
  const { dataTableId } = useParams();
  const [searchParams] = useSearchParams();

  // Current values
  const pageSize = searchParams.get('pageSize')!;
  const pageIndex = searchParams.get('pageIndex')!;
  const filters = searchParams.get('filters') ?? undefined;
  const orderBy = searchParams.get('orderBy') ?? undefined;

  const { project } = useActiveProject();
  const dataTables = queryClient.getQueryData<DataTablesSchema>(['project', project.id, 'data-tables'])!;
  const dataTableColumns =
    dataTables.objects?.find((x) => x.id === dataTableId)?.columns?.sort((a, b) => a.order! - b.order!) ?? [];
  const { data: tableData, isFetching: isTableDataFetching } = useGetProjectProjectIdDataTableDataTableIdData(
    project.id!,
    dataTableId!,
    {
      page: Number(pageIndex) + 1, // API index starts from 1
      per_page: Number(pageSize),
      filters: filters,
      order_by: orderBy
    },
    {
      query: {
        queryKey: ['tableData', dataTableId, pageIndex, pageSize, filters, orderBy],
        enabled: !!dataTableId,
        placeholderData: keepPreviousData,
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false
      }
    }
  );

  return (
    <div className="flex h-full grow-0 flex-col rounded-xl border-white bg-white">
      <DataTableNavigation />
      <DataTable dataTableColumns={dataTableColumns} data={tableData} isDataFetching={isTableDataFetching} />
    </div>
  );
});

export default DataTablesPage;
