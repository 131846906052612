import { memo } from 'react';

interface DividerProps {
  vertical?: boolean;
}

const Divider = memo(function Divider({ vertical }: DividerProps) {
  if (vertical) {
    return <div className="h-full w-px bg-divider-gray" />;
  }

  return <div className="h-px w-full bg-divider-gray" />;
});

export default Divider;
