import { useQueryClient } from '@tanstack/react-query';
import List from 'assets/list-framed.svg?react';
import { useGetProjectProjectIdDashboardActivityLog } from 'lib/dashboard/dashboard';
import { ActivityLogDefaultSchema, ProjectSchema } from 'lib/model';
import { memo, useMemo } from 'react';
import EventCard from './EventCard';
import EventsSkeleton from './EventsSkeleton';
import useActiveProject from 'contexts/project/projectContext';

function DashboardActivity() {
  const { project } = useActiveProject();
  const { data: activity, isPending: isActivityPending } = useGetProjectProjectIdDashboardActivityLog(project.id!);

  const activityPerDay = useMemo(() => {
    return activity?.objects?.reduce(
      (prev: Record<string, ActivityLogDefaultSchema[]>, curr: ActivityLogDefaultSchema) => {
        const date = new Date(curr.created_at!).toLocaleDateString();
        if (!prev[date]) {
          prev[date] = [];
        }
        prev[date].push(curr);
        return prev;
      },
      {}
    );
  }, [activity?.objects]);

  return (
    <div className="flex h-fit w-[320px] flex-col gap-2 rounded-xl bg-white p-4 max-lg:w-full lg:sticky lg:top-[98px]">
      <header className="pb-4 pt-2">
        <span className="text-sm font-semibold">Activity</span>
      </header>
      <div className="flex flex-col gap-6">
        {isActivityPending ? (
          <EventsSkeleton />
        ) : activity?.objects?.length && activity?.objects?.length > 0 ? (
          Object.entries(activityPerDay!).map(([date, events]) => (
            <div key={date} className="flex flex-col gap-4">
              <span className="text-tiny font-medium text-gray-500">{renderDate(date)}</span>
              <div className="flex flex-col gap-2">
                {events.map((event, i) => (
                  <EventCard key={`${date}-${i}`} event={event} />
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center gap-3 bg-gray-50 px-3 py-6">
            <List className="size-12 fill-gray-300" />
            <span className="text-xs font-medium text-gray-400">No activity yet</span>
          </div>
        )}
      </div>
    </div>
  );
}

function renderDate(date: string) {
  const today = new Date();
  const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);

  if (today.toLocaleDateString() === date) {
    return 'Today';
  } else if (yesterday.toLocaleDateString() === date) {
    return 'Yesterday';
  } else {
    return date;
  }
}

export default memo(DashboardActivity);
