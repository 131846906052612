/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as getProjectProjectIdDeploymentCurrentMutator,
  default as getProjectProjectIdDeploymentLatestMutator,
  default as getProjectProjectIdDeploymentMutator,
  default as getProjectProjectIdDeploymentProjectDeploymentIdMutator,
  default as postProjectProjectIdConfigurationProjectConfigurationIdMutator
} from '../../api/axios';
import type { ProjectDeploymentSchema, ProjectDeploymentsSchema } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Redeploys the specified project configuration creating a new project_deployment
 */
export const postProjectProjectIdConfigurationProjectConfigurationId = (
  projectId: string,
  projectConfigurationId: string,
  options?: SecondParameter<typeof postProjectProjectIdConfigurationProjectConfigurationIdMutator>
) => {
  return postProjectProjectIdConfigurationProjectConfigurationIdMutator<ProjectDeploymentSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/configuration/${encodeURIComponent(String(projectConfigurationId))}`,
      method: 'POST'
    },
    options
  );
};

export const getPostProjectProjectIdConfigurationProjectConfigurationIdMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>,
    TError,
    { projectId: string; projectConfigurationId: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdConfigurationProjectConfigurationIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>,
  TError,
  { projectId: string; projectConfigurationId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>,
    { projectId: string; projectConfigurationId: string }
  > = (props) => {
    const { projectId, projectConfigurationId } = props ?? {};

    return postProjectProjectIdConfigurationProjectConfigurationId(projectId, projectConfigurationId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdConfigurationProjectConfigurationIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>
>;

export type PostProjectProjectIdConfigurationProjectConfigurationIdMutationError = unknown;

/**
 * @summary Redeploys the specified project configuration creating a new project_deployment
 */
export const usePostProjectProjectIdConfigurationProjectConfigurationId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>,
    TError,
    { projectId: string; projectConfigurationId: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdConfigurationProjectConfigurationIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>,
  TError,
  { projectId: string; projectConfigurationId: string },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdConfigurationProjectConfigurationIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns a list of project_deployments
 */
export const getProjectProjectIdDeployment = (
  projectId: string,
  options?: SecondParameter<typeof getProjectProjectIdDeploymentMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDeploymentMutator<ProjectDeploymentsSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/deployment`, method: 'GET', signal },
    options
  );
};

export const getGetProjectProjectIdDeploymentQueryKey = (projectId: string) => {
  return [`/project/${projectId}/deployment`] as const;
};

export const getGetProjectProjectIdDeploymentQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDeployment>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDeployment>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdDeploymentMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdDeploymentQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDeployment>>> = ({ signal }) =>
    getProjectProjectIdDeployment(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDeployment>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDeploymentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDeployment>>
>;
export type GetProjectProjectIdDeploymentQueryError = unknown;

/**
 * @summary Returns a list of project_deployments
 */
export const useGetProjectProjectIdDeployment = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDeployment>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDeployment>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdDeploymentMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDeploymentQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns the current project_deployment
 */
export const getProjectProjectIdDeploymentCurrent = (
  projectId: string,
  options?: SecondParameter<typeof getProjectProjectIdDeploymentCurrentMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDeploymentCurrentMutator<ProjectDeploymentSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/deployment/current`, method: 'GET', signal },
    options
  );
};

export const getGetProjectProjectIdDeploymentCurrentQueryKey = (projectId: string) => {
  return [`/project/${projectId}/deployment/current`] as const;
};

export const getGetProjectProjectIdDeploymentCurrentQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDeploymentCurrent>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDeploymentCurrent>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdDeploymentCurrentMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdDeploymentCurrentQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDeploymentCurrent>>> = ({ signal }) =>
    getProjectProjectIdDeploymentCurrent(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDeploymentCurrent>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDeploymentCurrentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDeploymentCurrent>>
>;
export type GetProjectProjectIdDeploymentCurrentQueryError = unknown;

/**
 * @summary Returns the current project_deployment
 */
export const useGetProjectProjectIdDeploymentCurrent = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDeploymentCurrent>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDeploymentCurrent>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdDeploymentCurrentMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDeploymentCurrentQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns the latest project_deployment
 */
export const getProjectProjectIdDeploymentLatest = (
  projectId: string,
  options?: SecondParameter<typeof getProjectProjectIdDeploymentLatestMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDeploymentLatestMutator<ProjectDeploymentSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/deployment/latest`, method: 'GET', signal },
    options
  );
};

export const getGetProjectProjectIdDeploymentLatestQueryKey = (projectId: string) => {
  return [`/project/${projectId}/deployment/latest`] as const;
};

export const getGetProjectProjectIdDeploymentLatestQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDeploymentLatest>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDeploymentLatest>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdDeploymentLatestMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdDeploymentLatestQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDeploymentLatest>>> = ({ signal }) =>
    getProjectProjectIdDeploymentLatest(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDeploymentLatest>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDeploymentLatestQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDeploymentLatest>>
>;
export type GetProjectProjectIdDeploymentLatestQueryError = unknown;

/**
 * @summary Returns the latest project_deployment
 */
export const useGetProjectProjectIdDeploymentLatest = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDeploymentLatest>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDeploymentLatest>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdDeploymentLatestMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDeploymentLatestQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns a project_deployment
 */
export const getProjectProjectIdDeploymentProjectDeploymentId = (
  projectId: string,
  projectDeploymentId: string,
  options?: SecondParameter<typeof getProjectProjectIdDeploymentProjectDeploymentIdMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDeploymentProjectDeploymentIdMutator<ProjectDeploymentSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/deployment/${encodeURIComponent(String(projectDeploymentId))}`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetProjectProjectIdDeploymentProjectDeploymentIdQueryKey = (
  projectId: string,
  projectDeploymentId: string
) => {
  return [`/project/${projectId}/deployment/${projectDeploymentId}`] as const;
};

export const getGetProjectProjectIdDeploymentProjectDeploymentIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDeploymentProjectDeploymentId>>,
  TError = unknown
>(
  projectId: string,
  projectDeploymentId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDeploymentProjectDeploymentId>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDeploymentProjectDeploymentIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetProjectProjectIdDeploymentProjectDeploymentIdQueryKey(projectId, projectDeploymentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDeploymentProjectDeploymentId>>> = ({
    signal
  }) => getProjectProjectIdDeploymentProjectDeploymentId(projectId, projectDeploymentId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && projectDeploymentId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDeploymentProjectDeploymentId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDeploymentProjectDeploymentIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDeploymentProjectDeploymentId>>
>;
export type GetProjectProjectIdDeploymentProjectDeploymentIdQueryError = unknown;

/**
 * @summary Returns a project_deployment
 */
export const useGetProjectProjectIdDeploymentProjectDeploymentId = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDeploymentProjectDeploymentId>>,
  TError = unknown
>(
  projectId: string,
  projectDeploymentId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDeploymentProjectDeploymentId>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDeploymentProjectDeploymentIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDeploymentProjectDeploymentIdQueryOptions(
    projectId,
    projectDeploymentId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
