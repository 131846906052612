import CheckCircle from 'assets/check-circle.svg?react';
import Error from 'assets/close-circle-full.svg?react';
import Progress from 'assets/progress.svg?react';
import Warning from 'assets/warning.svg?react';

import StatusEnum from 'constants/enums/status';

const StatusIcon = ({ status, iconSize = 24 }: { status: StatusEnum; iconSize?: number }) => {
  switch (status) {
    case StatusEnum.ERROR:
      return <Error width={iconSize} height={iconSize} className="fill-red-600" />;
    case StatusEnum.WARNING:
      return <Warning width={iconSize} height={iconSize} className="fill-amber-600" />;
    case StatusEnum.RUNNING:
      return <Progress width={iconSize} height={iconSize} className="fill-blue-600" />;
    case StatusEnum.SUCCESS:
      return <CheckCircle width={iconSize} height={iconSize} className="fill-green-600" />;
    default:
      return null;
  }
};

export default StatusIcon;
