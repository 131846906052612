import { useQueryClient } from '@tanstack/react-query';
import Premium from 'assets/award.svg?react';
import Enhanced from 'assets/euro.svg?react';
import Affordable from 'assets/free.svg?react';
import DataSourceDataTypeEnum from 'constants/enums/dataSourceDataType';
import DataSourcePricingEnum from 'constants/enums/dataSourcePricing';
import { seasonOptions } from 'constants/generated';
import useDataSourcesFilters, {
  DataSourceFilters,
  defaultDataSourceFilters
} from 'contexts/dataSourcesFilters/dataSourcesFiltersContext';
import { useObjectsOptions, useStringEnumOptions } from 'hooks/useOptions';
import { DataTableSchema, DataTablesSchema } from 'lib/model';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import Checkbox from 'modules/common/Form/Checkbox';
import MultipleSelectButtons from 'modules/common/Form/MultipleSelectButtons';
import { memo, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonCheckboxOption, DialogProps } from 'utils/interfaces';
import DialogContent from '../../../common/Dialog/DialogContent';
import DialogFooter from '../../../common/Dialog/DialogFooter';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import useActiveProject from 'contexts/project/projectContext';

const DataSourcesFilterDialog = memo(function DataSourcesFilterDialog({ open, setOpen }: DialogProps) {
  const queryClient = useQueryClient();
  const filterStateOnOpen = useRef(defaultDataSourceFilters);
  const { project } = useActiveProject();
  const { dataSourcesFilters, setDataSourcesFilters } = useDataSourcesFilters();
  const { handleSubmit, register, control, reset } = useForm<DataSourceFilters>({
    defaultValues: defaultDataSourceFilters,
    values: dataSourcesFilters
  });

  const dataTables = queryClient.getQueryData<DataTablesSchema>(['project', project.id, 'data-tables']);

  const dataTableOptions = useObjectsOptions(dataTables, {
    getName: (obj: DataTableSchema) => obj.display_name
  });
  const dataTypeOptions = useStringEnumOptions(DataSourceDataTypeEnum);

  const filterDataSources = useCallback(
    function handleConfirm(data: DataSourceFilters) {
      setDataSourcesFilters({ ...data, source: 'filters' });
      setOpen(false);
    },
    [setOpen, setDataSourcesFilters]
  );

  const handleCancel = useCallback(
    function handleCancel() {
      reset(filterStateOnOpen.current);
      setOpen(false);
    },
    [setOpen, reset]
  );

  const handleClear = useCallback(
    function handleClear() {
      reset(defaultDataSourceFilters);
    },
    [reset]
  );

  return (
    <DialogBase title="Filter Data Sources" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form className="flex w-full flex-col gap-10" id="filter-form" onSubmit={handleSubmit(filterDataSources)}>
          <div className="flex w-full flex-col gap-4">
            <span className="text-md font-semibold">Price range</span>
            <MultipleSelectButtons name="pricing" options={pricingOptions} control={control} />
          </div>
          <div className="flex flex-col gap-6">
            <SelectInput
              formProps={{
                control: control,
                name: 'dataTypes'
              }}
              multiple
              label="Data types"
              placeholder="All data types"
              options={dataTypeOptions}
            />
            <SelectInput
              formProps={{
                control: control,
                name: 'dataTables'
              }}
              multiple
              label="Data tables"
              placeholder="All data tables"
              options={dataTableOptions}
            />
            <SelectInput
              formProps={{
                control: control,
                name: 'competitions'
              }}
              multiple
              label="Leagues"
              placeholder="All Leagues"
              disabled
              options={[{ id: '1', label: '1' }]}
            />
            <SelectInput
              formProps={{
                control: control,
                name: 'seasons'
              }}
              multiple
              label="Seasons"
              placeholder="All Seasons"
              disabled
              options={seasonOptions}
            />
            <Checkbox registerReturn={register('verified')} label="Only verified Data Sources" />
            <Checkbox registerReturn={register('useAssistant')} label="Find the best Data Source combinations" />
          </div>
        </form>
      </DialogContent>
      <DialogFooter>
        <button className="text-md font-semibold text-brand-800" onClick={handleClear}>
          Clear All
        </button>
        <div className="flex w-[454px] items-center gap-6">
          <Button variant="secondary" size="xl" isFullWidth={true} onClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button size="xl" isSubmitButton={true} form="filter-form">
            <span>Filter</span>
          </Button>
        </div>
      </DialogFooter>
    </DialogBase>
  );
});

const pricingOptions: ButtonCheckboxOption[] = [
  {
    id: DataSourcePricingEnum.AFFORDABLE,
    Icon: Affordable,
    label: 'Affordable'
  },
  {
    id: DataSourcePricingEnum.ENHANCED,
    Icon: Enhanced,
    label: 'Enhanced'
  },
  {
    id: DataSourcePricingEnum.PREMIUM,
    Icon: Premium,
    label: 'Premium'
  }
];

export default DataSourcesFilterDialog;
