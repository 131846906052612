import { useQueryClient } from '@tanstack/react-query';
import CheckCircle from 'assets/check-circle.svg?react';
import { AxiosError } from 'axios';
import { accessLevelPermissionList } from 'constants/accessLevelOptions';
import useActiveProject from 'contexts/project/projectContext';
import { ProjectGetSchema, ProjectRoleSchemaName, ProjectRolesSchema } from 'lib/model';
import { usePostProjectProjectIdInvite } from 'lib/project/project';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import MultipleValueInput from 'modules/common/Form/MultipleValueInput';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DialogProps, ValueOption } from 'utils/interfaces';
import { projectRoleToValueOption } from 'utils/mappings';

interface InviteUsersFormValues {
  emails: string[];
  accessLevel: ValueOption;
}

interface InviteUsersProps extends DialogProps {
  refreshUsers: () => void;
}

function InviteUsersDialog({ open, setOpen, refreshUsers }: InviteUsersProps) {
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const projectRoles = queryClient.getQueryData<ProjectRolesSchema>(['project', project.id!, 'roles'])?.objects;
  const projectRolesOptions = projectRoles
    ?.filter((x) => x.name !== ProjectRoleSchemaName.owner)
    .map(projectRoleToValueOption);

  const { control, watch, handleSubmit } = useForm<InviteUsersFormValues>({
    defaultValues: {
      emails: [],
      accessLevel: projectRolesOptions?.find((x) => x.value === ProjectRoleSchemaName.admin)
    }
  });
  const selectedLevel = watch('accessLevel');

  function handleCancel() {
    setOpen(false);
  }

  const { mutate: sendInvites } = usePostProjectProjectIdInvite();

  function inviteUsers(data: InviteUsersFormValues) {
    sendInvites(
      { data: { emails: data.emails, project_role_id: data.accessLevel.id as string }, projectId: project!.id! },
      {
        onSuccess: () => {
          refreshUsers();
          toast.success('User invites sent!');
          setOpen(false);
        },
        onError(err) {
          if (err instanceof AxiosError) {
            const error = err.response?.data.error;
            toast.error(error);
          }
        }
      }
    );
  }

  return (
    <DialogBase title="Invite users" open={open} onCancel={handleCancel}>
      <DialogContent>
        <div className="flex flex-col gap-10">
          <form id="inviteUsersForm" onSubmit={handleSubmit(inviteUsers)} className="flex gap-6">
            <div className="w-full">
              <MultipleValueInput
                name="emails"
                initialPlaceholder="Ex: user.name@gmail.com, user.name@yahoo.com"
                label="Enter emails"
                control={control}
                smaller
              />
            </div>
            <div className="w-2/5">
              <SelectInput
                formProps={{
                  control: control,
                  name: 'accessLevel'
                }}
                label="Access level"
                options={projectRolesOptions!}
              />
            </div>
          </form>
          <div className="flex flex-col gap-6 rounded-md bg-gray-50 p-6">
            <span className="text-md font-semibold">Access level: {selectedLevel.label}</span>
            <div className="flex flex-col gap-1">
              {accessLevelPermissionList
                .filter((x) => x.level.includes(selectedLevel.label as string))
                .map((p) => (
                  <div className="flex items-center gap-2" key={p.permission}>
                    <CheckCircle className="size-4 fill-green-600" />
                    <span className="text-xs font-medium">{p.permission}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" size="lg" isSubmitButton form="inviteUsersForm">
          Invite users
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(InviteUsersDialog);
