import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import CheckCircle from 'assets/check-circle.svg?react';
import LinkIcon from 'assets/link.svg?react';
import { useSelectedDataSources } from 'contexts/selectedDataSourcesContext';
import useIsMobile from 'hooks/useIsMobile';
import { DatasourceSchema, ProjectGetSchema } from 'lib/model';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import Divider from 'modules/common/Divider';
import ExternalLink from 'modules/common/ExternalLink';
import DataSourcePricingBadge from 'modules/dataSources/components/DataSourcePricingBadge';
import DataSourceDataTypeBadge from 'modules/dataSources/components/DataSourceTypeBadge';
import { memo } from 'react';

import useAuth from 'contexts/auth/authContext';
import { useGetProjectConfigurationProjectConfigurationIdDatasourceConfiguration } from 'lib/datasource-configuration/datasource-configuration';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Button from '../../../common/Button';
import DataSourcesCardBase from '../../components/DataSourcesCardBase';
import VerifiedBadge from './VerifiedBadge';
import useActiveProject from 'contexts/project/projectContext';

interface DataSourceCardProps {
  dataSource: DatasourceSchema;
  border?: boolean;
}

const DataSourceCard = memo(function DataSourceCard({ dataSource, border = false }: DataSourceCardProps) {
  const { isOwner, isAdmin } = useAuth();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { setSelectedDataSources } = useSelectedDataSources();
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const { data: connectedDataSources } = useGetProjectConfigurationProjectConfigurationIdDatasourceConfiguration(
    project!.project_configuration_latest!,
    {
      query: {
        queryKey: [
          'project',
          project.id,
          'configuration',
          project.project_configuration_latest,
          'datasource-configuration'
        ],
        staleTime: Infinity,
        enabled: !!project!.project_configuration_latest
      }
    }
  )!;

  const isDatasourceFetching =
    useIsFetching({
      queryKey: [
        'project',
        project.id,
        'configuration',
        project.project_configuration_latest,
        'datasource-configuration'
      ],
      exact: true
    }) > 0;
  const isProjectConfigurationsFetching =
    useIsFetching({
      queryKey: ['project', project.id, 'configuration', project.project_configuration_latest],
      exact: true
    }) > 0;

  const isDataSourceConnected =
    connectedDataSources?.objects!.find((x) => x.datasource === dataSource.id) !== undefined;

  function addDataSourceToSelected(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setSelectedDataSources((prev) => {
      return prev.concat({
        dataSource: dataSource,
        state: { expanded: prev.length === 0, authenticated: false, configured: false }
      });
    });
    navigate('/data-sources/activation');
  }

  function goToDataSourceDetails() {
    navigate(`/data-sources/${dataSource.id!}/details`);
  }

  return (
    <DataSourcesCardBase border={border} onClick={goToDataSourceDetails}>
      <div className="flex flex-wrap content-between items-center justify-between gap-4">
        <DataSourceTitle name={dataSource.name!} size="md" image={dataSource?.logo_image_path} />
        <div className="flex gap-4">
          <VerifiedBadge verified={dataSource.verified} />
          <DataSourcePricingBadge pricing={dataSource.pricing!} />
        </div>
      </div>
      <Divider />
      <p className={twMerge('grow text-sm font-medium text-gray-950', isMobile && 'text-xs')}>
        {dataSource.description}
      </p>
      <div className="flex flex-wrap justify-between gap-4">
        <ExternalLink href={dataSource.website!} />
        <div className="flex flex-wrap gap-2">
          {dataSource.data_types?.map((type) => <DataSourceDataTypeBadge type={type} key={type} />)}
        </div>
      </div>
      {isDatasourceFetching || isProjectConfigurationsFetching ? (
        <div className="grid h-8 w-full grid-cols-4 gap-4">
          <div className="animate-pulse rounded-md bg-gray-50" />
          <div className="bg-white" />
          <div className="bg-white" />
          <div className="animate-pulse rounded-md bg-gray-50" />
        </div>
      ) : (
        <>
          {isDataSourceConnected ? (
            <div className="flex items-center gap-2">
              <CheckCircle width={20} height={20} className="fill-green-600" />
              <span className="text-sm font-medium">Data Source connected</span>
            </div>
          ) : isOwner || isAdmin ? (
            <Button variant="secondary" size="md" onClick={addDataSourceToSelected}>
              <LinkIcon width={18} height={18} className="fill-black" />
              <span>Connect Data Source</span>
            </Button>
          ) : (
            <></>
          )}
        </>
      )}
    </DataSourcesCardBase>
  );
});

export default DataSourceCard;
