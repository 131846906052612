import { useQueryClient } from '@tanstack/react-query';
import { ProjectSchema, ReportMetricsSchema } from 'lib/model';
import SelectButton from 'modules/common/SelectButton';
import { memo, useCallback, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { MetricOption } from 'utils/interfaces';
import { ScoutReportFormValues } from '../interfaces';
import { metricToMetricOption } from 'utils/mappings';
import useActiveProject from 'contexts/project/projectContext';

interface ScoutReportManualFromMetricButtonProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
}

function ScoutReportManualFromMetricButton({ scoutReportForm }: ScoutReportManualFromMetricButtonProps) {
  const metric = useWatch({
    control: scoutReportForm.control,
    name: 'metric'
  })!;
  const seasons = useWatch({
    control: scoutReportForm.control,
    name: 'seasons'
  })!;

  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const playerMetrics = queryClient.getQueryData<ReportMetricsSchema>(['playerMetrics', project.id])!;

  const playerMetricOptions = useMemo(() => {
    const baseMetrics = playerMetrics.metrics ?? [];
    return baseMetrics.map(metricToMetricOption) ?? [];
  }, [playerMetrics, seasons]);

  const handleMetricChange = useCallback(
    function handleMetricChange(option: MetricOption) {
      scoutReportForm.setValue('metric', option);
    },
    [scoutReportForm]
  );

  return (
    <SelectButton
      loading={false}
      initial={metric}
      options={playerMetricOptions}
      size="md"
      onChange={handleMetricChange}
    />
  );
}

export default memo(ScoutReportManualFromMetricButton);
