import Invited from 'assets/mail-sent.svg?react';
import User from 'assets/user.svg?react';
import { ProjectSchema } from 'lib/model';
import { useProjectUsersStatus } from '../../hooks/useProjectUsersStatus';

interface SubscriptionCellProps {
  project: ProjectSchema;
}

const UsersCell = ({ project }: SubscriptionCellProps) => {
  const { isProjectUsersPending, activeUsers, invitedUsers } = useProjectUsersStatus(project.id!);
  return (
    <div className="flex flex-col gap-2 text-xs">
      <span className="flex items-center gap-1">
        <User className="size-5 fill-gray-700" /> {activeUsers.length}
      </span>
      <span className="flex items-center gap-1">
        <Invited className="size-5 fill-gray-700" /> {invitedUsers.length}
      </span>
    </div>
  );
};

export default UsersCell;
