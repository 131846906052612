import { useQueryClient } from '@tanstack/react-query';
import Info from 'assets/info.svg?react';
import Plus from 'assets/plus.svg?react';
import useActiveProject from 'contexts/project/projectContext';
import { useGetProjectProjectIdDashboardDatasourcesCount } from 'lib/dashboard/dashboard';
import { useGetProjectProjectIdDataTable } from 'lib/data-table/data-table';
import { ProjectGetSchema } from 'lib/model';
import Button from 'modules/common/Button';
import { memo, ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface DummyDataBannerProps {
  missingNote?: string | ReactElement;
  iteratingNote?: string | ReactElement;
}

const DummyDataBanner = memo(function DummyDataBanner({ missingNote, iteratingNote }: DummyDataBannerProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  function handleConnect() {
    navigate('/data-sources/all');
  }

  const { project } = useActiveProject();
  const { data: dataTables, isPending: isDataTablesPending } = useGetProjectProjectIdDataTable(project.id!, {
    query: {
      queryKey: ['project', project.id, 'data-tables'],
      staleTime: Infinity
    }
  });

  const { data: dataSourcesCount, isPending: isDataSourceCountPending } =
    useGetProjectProjectIdDashboardDatasourcesCount(project.id!, {
      query: { queryKey: ['project', project.id, 'data-sources', 'count'], enabled: !!project.id, staleTime: Infinity }
    });

  const renderElement = useCallback(() => {
    if (isDataTablesPending || isDataSourceCountPending) {
      return null;
    }

    if (dataSourcesCount?.count === 0) {
      return (
        <div className="flex w-full items-center justify-between gap-6 rounded-xl bg-white p-10">
          <div className="flex gap-6">
            <Info width={64} height={64} className="min-h-16 min-w-16 fill-gray-300" />
            <p className="flex flex-col justify-center gap-0.5 text-md font-medium">
              <div>
                <span className="font-bold">Connect data sources</span> for full access to the latest and most
                up-to-date information.
              </div>
              {missingNote && (
                <>
                  <div>{missingNote}</div>
                  <br />
                </>
              )}
            </p>
          </div>
          <Button variant="primary" size="xl" isFullWidth={false} onClick={handleConnect}>
            <Plus width={20} height={20} />
            <span>Connect Data Sources</span>
          </Button>
        </div>
      );
    } else if (dataTables?.dummy_data) {
      return (
        <div className="flex w-full items-center justify-between gap-6 rounded-xl bg-white p-10">
          <div className="flex gap-6">
            <Info width={64} height={64} className="min-h-16 min-w-16 fill-gray-300" />
            <p className="flex flex-col justify-center gap-0.5 text-md font-medium">
              <div>
                <span className="font-bold">Data sources are syncing.</span>
                You can check the synchronization progress in{' '}
                <span
                  className="cursor-pointer font-bold text-brand-800"
                  onClick={() => {
                    navigate('/data-sources/connected');
                  }}
                >
                  Data Sources
                </span>
                . Until then, you can explore demo data in{' '}
                <span
                  className="cursor-pointer font-bold text-brand-800"
                  onClick={() => {
                    if (dataTables) {
                      navigate(`/data-tables/${dataTables.objects?.at(0)?.id}`);
                    }
                  }}
                >
                  Data Tables
                </span>
                .
              </div>
              {iteratingNote && (
                <>
                  <br />
                  <div>{iteratingNote}</div>
                </>
              )}
            </p>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }, [dataSourcesCount, dataTables, iteratingNote, missingNote, isDataTablesPending, isDataSourceCountPending]);

  return renderElement();
});

export default DummyDataBanner;
