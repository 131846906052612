import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';
import { ProjectGetSchema, ProjectUserSchema, ProjectUsersSchema } from 'lib/model';
import { usePostProjectProjectIdSettingsBiConnection } from 'lib/settings/settings';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import SettingsUserSelectInput from 'modules/common/Form/SettingsUserSelectInput';
import { memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { isActiveUser } from 'utils/helpers';
import { DialogProps } from 'utils/interfaces';

interface CreateConnectionFormValues {
  user: ProjectUserSchema | null;
}

function CreateConnectionDialog({ open, setOpen }: DialogProps) {
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const projectUsers = queryClient.getQueryData<ProjectUsersSchema>(['project', project.id, 'users'])!.objects!;

  const { handleSubmit, control, reset, formState } = useForm<CreateConnectionFormValues>({
    defaultValues: { user: null }
  });
  const { mutate: createConnection, isPending } = usePostProjectProjectIdSettingsBiConnection();

  function handleCancel() {
    reset();
    setOpen(false);
  }

  const userOptions = useMemo(() => projectUsers.filter(isActiveUser), [projectUsers]);

  function onCreateConnectionSubmit(data: CreateConnectionFormValues) {
    createConnection(
      { projectId: project.id!, data: { user_account: data.user?.user_account_id } },
      {
        onSuccess: () => {
          reset();
          toast.success('Connection successfully created.');
          setOpen(false);
          queryClient.invalidateQueries({ queryKey: ['biToolConnections'] });
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response?.data.error;
            toast.error(error);
          }
        }
      }
    );
  }

  return (
    <DialogBase title="Create Connection" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onCreateConnectionSubmit)}
          className="flex flex-col gap-6"
          id="username-change-form"
        >
          <span className="text-md font-semibold">Create connection for user:</span>
          <SettingsUserSelectInput
            label="Choose user"
            name="user"
            control={control}
            options={userOptions}
            rules={{ required: { value: true, message: 'User is required' } }}
            error={formState.errors.user}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <Button size="xl" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" size="xl" isSubmitButton={true} form="username-change-form" loading={isPending}>
          Create connection
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(CreateConnectionDialog);
