import { ProjectGetSchema } from 'lib/model';
import { createContext, useContext } from 'react';

export interface ProjectContext {
  project: ProjectGetSchema;
  changeActiveProject: (projectId: string) => void;
}

export const ProjectContext = createContext<Partial<ProjectContext>>({});

export default function useActiveProject() {
  const context = useContext(ProjectContext) as ProjectContext;

  if (context === undefined) {
    throw new Error('ProjectContext was used outside of ProjectContextProvider');
  }

  return context;
}
