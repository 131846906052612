import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';
import { ProjectSchema, ReportCreateSchemaReportType } from 'lib/model';
import { useGetReportGetTeamsInfinite, usePostReport } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AutocompleteOption, DialogProps } from 'utils/interfaces';
import { teamToAutocompleteOption } from 'utils/mappings';

interface NewTeamReportFormValues {
  name: string;
  team: AutocompleteOption | null;
}

const NewTeamReportDialog = memo(function NewTeamReportDialog({ open, setOpen }: DialogProps) {
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const {
    handleSubmit: formSubmit,
    register,
    control,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm<NewTeamReportFormValues>({
    defaultValues: {
      name: '',
      team: null
    }
  });

  const queryClient = useQueryClient();
  const { project } = useActiveProject();

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, status } = useGetReportGetTeamsInfinite(
    {
      team_name: query,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['teams', project.id, query],
        staleTime: 1000 * 60 * 5,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages, lastPageParam) => {
          if (!lastPage.teams || lastPage.teams?.length < 100) {
            return undefined;
          }

          return lastPageParam ? lastPageParam + 1 : 1;
        }
      }
    }
  );

  const teamOptions = useMemo(() => {
    const allRows = data ? data.pages.flatMap((d) => d.teams) : [];
    return allRows.map(teamToAutocompleteOption) ?? [];
  }, [data]);

  const { mutate: createReport, isPending: isCreating } = usePostReport();

  function handleSubmit(data: NewTeamReportFormValues) {
    if (!data.team) return;
    createReport(
      {
        data: {
          name: data.name,
          report_type: ReportCreateSchemaReportType.team,
          project: project!.id!,
          report_entities: [data.team.id as number]
        }
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ['reports'] });
          navigate(`/reports/${res.id!}`);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            setError('root', { message: err.response?.data.error, type: 'backend-validation' });
          }
        }
      }
    );
  }

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const selectedTeam = watch('team');
  useEffect(() => {
    if (selectedTeam) {
      let reportName = `${selectedTeam.label}`;
      if (selectedTeam.secondaryLabel) {
        reportName = reportName.concat(` | ${selectedTeam.secondaryLabel}`);
      }
      setValue('name', reportName);
    } else {
      setValue('name', '');
    }
  }, [selectedTeam, setValue]);

  return (
    <DialogBase title="New Team Report" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form className="flex w-full flex-col gap-10" id="new-team-report-form" onSubmit={formSubmit(handleSubmit)}>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Find Team</span>
            <SelectInput
              loading={status === 'pending'}
              label={'Team'}
              placeholder="Start typing: Team name / Team country"
              options={teamOptions}
              error={errors.team}
              onInputChange={setQuery}
              searchable
              infiniteQuery={{
                hasNextPage: hasNextPage,
                fetchNextPage: fetchNextPage,
                isFetchingNextPage: isFetchingNextPage
              }}
              formProps={{
                name: 'team',
                rules: { required: { value: true, message: 'Team is required.' } },
                control: control
              }}
            />
          </div>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Report Name</span>
            <Input
              label="Enter report name"
              registerReturn={register('name', { required: 'Report Name is required' })}
              error={errors.name}
            />
          </div>
          {errors?.root && <ErrorMessage error={errors.root} />}
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" disabled={isCreating} onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="new-team-report-form" loading={isCreating}>
          <span>Create Team Report</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default NewTeamReportDialog;
