import { useQueryClient } from '@tanstack/react-query';
import useAuth from 'contexts/auth/authContext';
import useActiveProject from 'contexts/project/projectContext';
import { ProjectRolesSchema, ProjectUserSchema, ReportEditSchema, ReportNestedSchema } from 'lib/model';
import { useGetProjectProjectIdUser } from 'lib/project-user/project-user';
import { usePutReportReportId } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import SettingsUserSelectInput from 'modules/common/Form/SettingsUserSelectInput';
import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { isActiveUser } from 'utils/helpers';
import { DialogProps } from 'utils/interfaces';

interface TransferReportForm {
  user: ProjectUserSchema | null;
}

interface TransferReportDialogProps extends DialogProps {
  report: ReportNestedSchema;
}

function TransferReportDialog({ open, setOpen, report }: TransferReportDialogProps) {
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const { user: currentUser } = useAuth();

  const projectRoles = queryClient.getQueryData<ProjectRolesSchema>(['project', project.id!, 'roles']);
  const projectUsers = useGetProjectProjectIdUser(report.project!, {
    query: {
      queryKey: ['projectUsers', report.project],
      enabled: !!report?.project,
      staleTime: Infinity
    }
  });

  const rolesMapping = useMemo(() => {
    return projectRoles!.objects!.reduce(
      (acc, role) => {
        acc[role.id!] = role.name!;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [projectRoles]);

  const userOptions = useMemo(() => {
    return (
      projectUsers.data?.objects
        ?.filter((user) => user.user_account_id !== currentUser.id)
        .filter(isActiveUser)
        .filter((user) => rolesMapping[user.project_role_id!] !== 'viewer') ?? []
    );
  }, [projectUsers, currentUser, rolesMapping]);

  const { control, handleSubmit, formState, setError } = useForm<TransferReportForm>({
    defaultValues: {
      user: null
    }
  });

  const { mutate: updateReport, isPending: isUpdatingReport } = usePutReportReportId({
    mutation: {
      onMutate: async (body) => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        await queryClient.cancelQueries({ queryKey: ['reports', report.id!] });
        setOpen(false);
        return { loadingToastId };
      },
      onError: (err, body, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.error('Report owner change failed');
      },
      onSuccess: (err, body, context) => {
        queryClient.invalidateQueries({ queryKey: ['reports', 'filtered'] });
        queryClient.invalidateQueries({ queryKey: ['reports', report.id!], exact: true });
        toast.dismiss(context?.loadingToastId);
        toast.success('Report owner changed successfully');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          exact: true,
          queryKey: ['reports', report.id!]
        });
      }
    }
  });

  function handleTransfer(data: TransferReportForm) {
    if (data.user?.user_account_id === report.owner_user) {
      setError(
        'user',
        { message: "The new owner can't be the same as the old one.", type: 'validate' },
        { shouldFocus: true }
      );
      return;
    }

    const updatedData = {
      reportId: report.id!,
      data: {
        owner_user: data.user?.user_account_id as string
      } satisfies ReportEditSchema
    };
    updateReport(updatedData);
  }

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  return (
    <DialogBase title="Transfer Report" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form className="flex flex-col gap-3" id="reportTransferForm" onSubmit={handleSubmit(handleTransfer)}>
          <div className="text-md font-medium">Transfer ownership of this report to another user</div>
          <SettingsUserSelectInput
            label="Choose new report owner"
            name={`user`}
            control={control}
            options={userOptions}
            error={formState.errors.user}
            rules={{ required: 'This field is required' }}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <Button size="xl" variant="secondary" onClick={handleCancel} disabled={isUpdatingReport}>
          Cancel
        </Button>
        <Button size="xl" isSubmitButton form="reportTransferForm" disabled={isUpdatingReport}>
          Transfer Report
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(TransferReportDialog);
