import { AuthProvider } from 'contexts/auth/AuthProvider';
import { ProjectProvider } from 'contexts/project/ProjectProvider';
import { memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AuthGuard = memo(function AuthGuard() {
  const token = localStorage.getItem('token');

  if (!token) {
    return Navigate({ to: `/login?redirect=${window.location.pathname}${window.location.search}` });
  }

  return (
    <AuthProvider>
      <ProjectProvider>
        <Outlet />
      </ProjectProvider>
    </AuthProvider>
  );
});

export default AuthGuard;
