import { useQueryClient } from '@tanstack/react-query';
import useAuth from 'contexts/auth/authContext';
import { useGetProjectConfigurationProjectConfigurationIdDatasourceConfiguration } from 'lib/datasource-configuration/datasource-configuration';
import { ProjectGetSchema } from 'lib/model';
import { memo } from 'react';
import DashboardSectionSkeleton from '../DashboardCardsSkeleton';
import DashboardActiveDataSources from './ActiveDataSources/DashboardActiveDataSources';
import DashboardFeaturedDataSources from './FeaturedDataSources/DashboardFeaturedDataSources';
import DashboardIntegrationStatus from './Integration/DashboardIntegrationStatus';
import DashboardIntegrationStatsSkeleton from './Integration/DashboardIntegrationStatusSkeleton';
import useActiveProject from 'contexts/project/projectContext';

function DashboardDataSources() {
  const { isOwner } = useAuth();
  const { project } = useActiveProject();
  const { data: dataSourceConfigurations, isPending: isDataSourceConfigurationsPending } =
    useGetProjectConfigurationProjectConfigurationIdDatasourceConfiguration(project!.project_configuration_latest!, {
      query: {
        queryKey: [
          'project',
          project.id,
          'configuration',
          project.project_configuration_latest,
          'datasource-configuration'
        ],
        staleTime: Infinity,
        enabled: !!project!.project_configuration_latest
      }
    });

  if (project!.project_configuration_latest && isDataSourceConfigurationsPending) {
    return (
      <>
        <DashboardIntegrationStatsSkeleton />
        <DashboardSectionSkeleton />
      </>
    );
  }

  const hasActiveDataSources =
    dataSourceConfigurations?.objects?.length && dataSourceConfigurations?.objects?.length > 0;

  if (!hasActiveDataSources) {
    if (isOwner) {
      return <DashboardFeaturedDataSources />;
    } else {
      return (
        <DashboardActiveDataSources
          isDataSourceConfigurationsPending={isDataSourceConfigurationsPending}
          datasourceConfigurations={dataSourceConfigurations?.objects ?? []}
        />
      );
    }
  }

  return (
    <>
      <DashboardIntegrationStatus />
      <DashboardActiveDataSources
        isDataSourceConfigurationsPending={isDataSourceConfigurationsPending}
        datasourceConfigurations={dataSourceConfigurations?.objects ?? []}
      />
    </>
  );
}

export default memo(DashboardDataSources);
