import { useQueryClient } from '@tanstack/react-query';
import Add from 'assets/plus.svg?react';
import { PlayerQuerySchema, PlayersQuerySchema, ProjectSchema } from 'lib/model';
import { useGetReportGetPlayersInfinite } from 'lib/report/report';
import Button from 'modules/common/Button';
import Fieldset from 'modules/common/Form/Fieldset';
import { memo, useCallback, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { AutocompleteOption, ValueOption } from 'utils/interfaces';
import { ScoutReportFormValues } from './interfaces';
import { playerToAutocompleteOption } from 'utils/mappings';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import useActiveProject from 'contexts/project/projectContext';

interface AddManualPlayerFormValues {
  player: AutocompleteOption | null;
}

interface ScoutReportAddManualPlayerProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
  isAddingPlayer: boolean;
  setIsAddingPlayer: React.Dispatch<React.SetStateAction<boolean>>;
  position: ValueOption | null;
  totalPlayers: number;
  currentPlayers: PlayerQuerySchema[];
}

function ScoutReportAddManualPlayer({
  scoutReportForm,
  currentPlayers,
  isAddingPlayer,
  setIsAddingPlayer,
  position,
  totalPlayers
}: ScoutReportAddManualPlayerProps) {
  const addPlayerForm = useForm<AddManualPlayerFormValues>({
    defaultValues: {
      player: null
    }
  });

  const [query, setQuery] = useState<string>('');
  const { project } = useActiveProject();

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, status } = useGetReportGetPlayersInfinite(
    {
      player_name: query,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['players', project.id, query],
        staleTime: 1000 * 60 * 5,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages, lastPageParam) => {
          if (!lastPage.players || lastPage.players?.length < 100) {
            return undefined;
          }

          return lastPageParam ? lastPageParam + 1 : 1;
        }
      }
    }
  );

  const playerOptions = useMemo(() => {
    const allRows = (data ? data.pages.flatMap((d) => d.players) : []) as PlayerQuerySchema[];
    return (
      allRows
        .filter((player) => !currentPlayers.some((curr) => curr.player_id === player.player_id))
        .map(playerToAutocompleteOption) ?? []
    );
  }, [data, currentPlayers]);

  const players: PlayersQuerySchema = useMemo(() => {
    const allPlayers = (data ? data.pages.flatMap((d) => d.players) : []) as PlayerQuerySchema[];
    return { players: allPlayers };
  }, [data]);

  const toggleAddPlayer = useCallback(
    function toggleAddPlayer() {
      setIsAddingPlayer((state) => !state);
    },
    [setIsAddingPlayer]
  );

  const addManualPlayer = useCallback(
    function addManualPlayer(data: AddManualPlayerFormValues) {
      const playersOld = scoutReportForm.getValues('players');
      const player = players!.players!.find((p) => p.player_id === data.player?.id);
      if (player) {
        scoutReportForm.setValue('players', [...playersOld, player]);
      }
      setIsAddingPlayer((state) => !state);
      addPlayerForm.reset();
    },
    [addPlayerForm, players, scoutReportForm, setIsAddingPlayer]
  );

  const playerDescription = useMemo(
    () => (
      <div className="flex items-center gap-2">
        <span className="text-sm text-gray-500">Player position:</span>
        <span className="text-sm font-medium">{position?.label}</span>
      </div>
    ),
    [position]
  );

  return !isAddingPlayer ? (
    <Button variant="secondary" onClick={toggleAddPlayer} disabled={totalPlayers >= 20}>
      <Add width={20} height={20} />
      <span>Add player</span>
    </Button>
  ) : (
    <form id="scout-report-add-player-form" onSubmit={addPlayerForm.handleSubmit(addManualPlayer)}>
      <Fieldset legend="Add Player" description={playerDescription}>
        <SelectInput
          loading={status === 'pending'}
          label={'Player'}
          placeholder="Start typing: Player name / Team / Player country"
          options={playerOptions}
          onInputChange={setQuery}
          searchable
          infiniteQuery={{
            hasNextPage: hasNextPage,
            fetchNextPage: fetchNextPage,
            isFetchingNextPage: isFetchingNextPage
          }}
          formProps={{
            name: 'player',
            rules: { required: { value: true, message: 'Player is required.' } },
            control: addPlayerForm.control
          }}
        />
        <div className="ml-auto mt-4 flex w-1/2 items-center gap-4">
          <Button variant="secondary" size="lg" onClick={toggleAddPlayer}>
            <span>Cancel</span>
          </Button>
          <Button
            size="lg"
            isSubmitButton
            form="scout-report-add-player-form"
            disabled={!addPlayerForm.watch('player')}
          >
            <span>Add player</span>
          </Button>
        </div>
      </Fieldset>
    </form>
  );
}

export default memo(ScoutReportAddManualPlayer);
