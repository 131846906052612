import useActiveProject from 'contexts/project/projectContext';
import { useGetProjectProjectIdDataTable } from 'lib/data-table/data-table';
import { useGetProjectProjectIdRole } from 'lib/project-role/project-role';
import { useGetProjectProjectIdUser } from 'lib/project-user/project-user';
import { useGetProjectProjectIdSubscription } from 'lib/subscription/subscription';

export default function useInitialDataLoad() {
  const { project } = useActiveProject();

  const { isFetching: isDataTablesFethcing } = useGetProjectProjectIdDataTable(project.id!, {
    query: {
      enabled: !!project.id,
      queryKey: ['project', project.id, 'data-tables'],
      staleTime: Infinity
    }
  });

  const { data: activeTier, isPending: isFetchingTier } = useGetProjectProjectIdSubscription(
    project.id!,
    {},
    { query: { queryKey: ['project', project.id!, 'subscription-tier'], staleTime: Infinity, enabled: !!project.id } }
  );

  const {
    isFetching: isProjectUsersFetching,
    data: projectUsers,
    isError: isProjectUsersError
  } = useGetProjectProjectIdUser(project.id!, {
    query: {
      enabled: !!project.id,
      queryKey: ['project', project.id, 'users'],
      retry: false,
      refetchOnWindowFocus: false
    }
  });

  // Load for other elements?
  useGetProjectProjectIdRole(project.id!, {
    query: { queryKey: ['project', project.id, 'roles'], retry: false, staleTime: Infinity, enabled: !!project.id }
  });

  const isLoading = isProjectUsersFetching || isDataTablesFethcing || isFetchingTier;
  const shouldInviteUsers = !isProjectUsersError && projectUsers?.objects?.length === 1;

  return { project, isLoading, shouldInviteUsers, activeTier };
}
