/* eslint-disable react/jsx-no-bind */
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { defaultPageIndex } from 'constants/tableDefaults';
import useAuth from 'contexts/auth/authContext';
import useActiveProject from 'contexts/project/projectContext';
import { DataTableSchema, DataTablesSchema } from 'lib/model';
import { memo } from 'react';
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { twJoin, twMerge } from 'tailwind-merge';

const DataTableNavigation = memo(function DataTableNavigation() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const dataTables = queryClient.getQueryData<DataTablesSchema>(['project', project.id, 'data-tables'])!;
  const { isViewer } = useAuth();

  const { dataTableId } = useParams();
  const [searchParams] = useSearchParams();

  const dataTableGroups = dataTables?.objects!.reduce((all: Record<string, DataTableSchema[]>, curr) => {
    const key = curr.table_type ?? 'other';
    if (all[key]) {
      all[key].push(curr);
    } else {
      all[key] = [curr];
    }
    return all;
  }, {});

  const selectedDataTable = dataTables!.objects!.find((x) => x.id === dataTableId);
  const selectedTabIndex = Object.keys(dataTableGroups).findIndex((x) => x === selectedDataTable?.table_type);

  function goToBiTools() {
    navigate('/settings/bi-tool-integration');
  }

  function openFirstDataTable(key: string) {
    navigate(
      `/data-tables/${dataTableGroups[key][0].id!}?pageIndex=${defaultPageIndex}&pageSize=${searchParams.get('pageSize')}`
    );
  }

  return (
    <TabGroup className="flex flex-col" defaultIndex={selectedTabIndex}>
      <div className="flex items-center justify-between pr-6">
        <TabList className="flex flex-wrap items-center gap-2 rounded-md rounded-t-xl bg-white p-4">
          {dataTableGroups &&
            Object.keys(dataTableGroups).map((key) => {
              const exists = dataTableGroups[key].some((table) => table.exists);

              return (
                <Tab
                  key={key}
                  className={twJoin(
                    'rounded-md bg-transparent px-4 py-2.5 text-center text-xs font-semibold uppercase focus:outline-none data-[selected]:bg-brand-50 data-[selected]:text-brand-800',
                    exists && 'text-gray-600',
                    !exists && 'pointer-events-none text-gray-400'
                  )}
                  onClick={() => openFirstDataTable(key)}
                >
                  {key}
                </Tab>
              );
            })}
        </TabList>
        {!isViewer && (
          <span className="cursor-pointer text-sm font-medium text-brand-800" onClick={goToBiTools}>
            Connect to BI tools
          </span>
        )}
      </div>
      <TabPanels className="bg-brand-50">
        {dataTableGroups &&
          Object.values(dataTableGroups).map((key, index) => (
            <TabPanel key={index} className={twJoin('group flex items-center gap-2 overflow-x-auto px-4')}>
              {key.map((dataTable) => (
                <NavLink
                  key={dataTable.id}
                  to={`/data-tables/${dataTable.id!}?pageIndex=${defaultPageIndex}&pageSize=${searchParams.get('pageSize')}`}
                  className={twMerge(
                    'whitespace-nowrap bg-transparent px-4 py-5 text-center text-xs font-semibold uppercase text-gray-600',
                    dataTable.id === dataTableId && 'border-b-4 border-brand-800 text-brand-800',
                    !dataTable.exists && 'pointer-events-none text-gray-400'
                  )}
                >
                  {dataTable.display_name}
                </NavLink>
              ))}
            </TabPanel>
          ))}
      </TabPanels>
    </TabGroup>
  );
});

export default DataTableNavigation;
