/* eslint-disable react-refresh/only-export-components */
import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Portal, Transition } from '@headlessui/react';
import ChevronDown from 'assets/chevron-down.svg?react';
import { ProjectGetSchema } from 'lib/model';
import CircleImage from 'modules/common/CircleImage';
import { Fragment, memo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface ProjectSelectInputProps {
  defaultProject?: ProjectGetSchema;
  options: ProjectGetSchema[];
  label: string;
  handleChange: (value: ProjectGetSchema | null) => void;
}

function ProjectSelectInput({ options, label, defaultProject, handleChange }: ProjectSelectInputProps) {
  const [formValue, setFormValue] = useState<ProjectGetSchema | null>(defaultProject ?? null);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`
          });
        }
      } as SizeOptions)
    ]
  });

  async function onHandleChange(value: ProjectGetSchema | null) {
    handleChange(value);
    setFormValue(value);
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <Listbox value={formValue} onChange={onHandleChange} by="id">
        {({ open, value }) => (
          <>
            <div className="relative">
              <Label
                className={twMerge(
                  'pointer-events-none absolute start-3 top-1.5 z-10 origin-[0] translate-y-2.5 transform text-gray-500 duration-300',
                  value && 'hidden'
                )}
              >
                {label}
              </Label>
              <ListboxButton
                ref={refs.setReference}
                className="h-14 w-full cursor-pointer rounded-md border border-gray-300 bg-white px-3 text-left text-md focus:outline-none focus:ring-0 ui-disabled:bg-gray-50"
              >
                {formValue && (
                  <>
                    <div className="flex items-center gap-3 rounded-md py-1">
                      <CircleImage
                        image={formValue?.organization_image_path}
                        text={formValue!.organization_name!.charAt(0)}
                        size="size-8"
                      />
                      {formValue.organization_name}
                    </div>
                  </>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDown width={16} height={16} className="absolute right-2 fill-gray-950" />
                </span>
              </ListboxButton>
              {open && (
                <Portal>
                  <div ref={refs.setFloating} className="z-50 w-full" style={floatingStyles}>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <ListboxOptions className="max-h-60 overflow-y-auto rounded-md bg-white py-3 shadow-card ring-0 focus:outline-none">
                        {options.map((option) => (
                          <ListboxOption
                            key={option.organization_name}
                            className="cursor-pointer select-none px-2 py-1 ui-active:bg-gray-50"
                            value={option}
                          >
                            <div className="flex items-center gap-3 rounded-md px-1.5 py-2 ui-selected:bg-brand-50">
                              <CircleImage
                                image={option?.organization_image_path}
                                text={option!.organization_name!.charAt(0)}
                                size="size-8"
                              />
                              {option.organization_name}
                            </div>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </Transition>
                  </div>
                </Portal>
              )}
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

export default memo(ProjectSelectInput) as typeof ProjectSelectInput;
