import MoveDown from 'assets/chevron-down.svg?react';
import MoveUp from 'assets/chevron-up.svg?react';
import { DatasourceSchema } from 'lib/model';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import { memo } from 'react';

interface DataSourcePriorityOrderProps {
  dataSourceOrder: DatasourceSchema[];
  setDataSourceOrder: (newOrder: DatasourceSchema[]) => void;
  editing: boolean;
  disabled?: boolean;
}

const DataSourcePriorityOrder = memo(function DataSourcePriorityOrder({
  dataSourceOrder,
  setDataSourceOrder,
  editing,
  disabled
}: DataSourcePriorityOrderProps) {
  function handleMoveUp(index: number) {
    if (index === 0) {
      return;
    }
    const newOrder = [...dataSourceOrder];
    [newOrder[index - 1], newOrder[index]] = [newOrder[index], newOrder[index - 1]];
    setDataSourceOrder(newOrder);
  }

  function handleMoveDown(index: number) {
    if (index >= dataSourceOrder.length - 1) {
      return;
    }
    const newOrder = [...dataSourceOrder];
    [newOrder[index + 1], newOrder[index]] = [newOrder[index], newOrder[index + 1]];
    setDataSourceOrder(newOrder);
  }

  return (
    <>
      <div className="flex flex-col gap-6">
        {dataSourceOrder.map((dataSource, index) => (
          <div className="flex w-full" key={dataSource.id}>
            <DataSourceTitle name={dataSource.name!} size={'md'} image={dataSource?.logo_image_path} />
            {editing && (
              <>
                <div className="flex flex-col items-center divide-y rounded-sm border border-gray-300">
                  <button
                    onClick={() => {
                      handleMoveUp(index);
                    }}
                    className="group flex items-center justify-center disabled:cursor-not-allowed"
                    disabled={index === 0 || disabled}
                  >
                    <MoveUp className="size-5 fill-gray-700 group-disabled:fill-gray-400" />
                  </button>
                  <button
                    onClick={() => {
                      handleMoveDown(index);
                    }}
                    className="group flex items-center justify-center disabled:cursor-not-allowed"
                    disabled={index === dataSourceOrder.length - 1 || disabled}
                  >
                    <MoveDown className="size-5 fill-gray-700 group-disabled:fill-gray-400" />
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </>
  );
});

export default DataSourcePriorityOrder;
