import CloseCircle from 'assets/close-circle.svg?react';
import Edit from 'assets/edit.svg?react';
import Replace from 'assets/replace.svg?react';
import Warning from 'assets/warning.svg?react';
import DataSourceAuth from 'constants/enums/dataSourceAuth';
import { SelectedDataSource, useSelectedDataSources } from 'contexts/selectedDataSourcesContext';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import Divider from 'modules/common/Divider';
import { memo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { getDataSourceAuthType } from 'utils/helpers';
import DataSourceAuthentication from './authentication/DataSourceAuthentication';
import ConfigurationDisplay from './ConfigurationDisplay';
import ConfigurationForm from './ConfigurationForm';
import ReplaceDataSourceDialog from './ReplaceDataSourceDialog';
import useActiveProject from 'contexts/project/projectContext';

const DataSourceActivationCard = memo(function DataSourceActivationCard({ dataSource, state }: SelectedDataSource) {
  const [showReplaceDialog, setShowReplaceDialog] = useState<boolean>(false);
  const { selectedDataSources, setSelectedDataSources } = useSelectedDataSources();
  const authType = getDataSourceAuthType(dataSource);
  const setupDone = (state.authenticated || authType === DataSourceAuth.NONE) && state.configured;
  const setupInterrupted = !state.expanded && state.authenticated && !state.configured;
  const { project } = useActiveProject();

  function remove() {
    setSelectedDataSources((prev) => {
      const next = prev.filter((x) => x.dataSource.id !== dataSource.id);
      if (!next[0].state.expanded) {
        next[0].state.expanded = true;
      }
      return next;
    });
  }

  function replace() {
    setShowReplaceDialog(true);
  }

  function authenticate(value: boolean) {
    setSelectedDataSources((prev) => {
      return prev.map((x) => {
        if (x.dataSource.id === dataSource.id) {
          return {
            ...x,
            state: { ...x.state, authenticated: value }
          };
        }
        return x;
      });
    });
  }

  function edit() {
    setSelectedDataSources((prev) => {
      return prev.map((x) => {
        if (x.dataSource.id === dataSource.id) {
          return {
            ...x,
            state: { ...x.state, configured: false }
          };
        }
        return x;
      });
    });
  }

  return (
    <div
      className={twJoin(
        'flex flex-col gap-6 p-10 transition-all duration-500 ease-linear sm:rounded-lg',
        state.expanded ? 'bg-white' : 'bg-gray-50'
      )}
    >
      <ReplaceDataSourceDialog oldDataSource={dataSource} open={showReplaceDialog} setOpen={setShowReplaceDialog} />
      <div className="flex items-center justify-between">
        <DataSourceTitle name={dataSource.name!} image={dataSource?.logo_image_path} size="md" />
        <div className="flex w-[176px] items-center justify-end gap-4">
          {setupDone && (
            <div className="flex cursor-pointer items-center gap-1" onClick={edit}>
              <span className="text-xs font-medium text-brand-800">Edit</span>
              <Edit width={16} height={16} className="fill-brand-800" />
            </div>
          )}
          {selectedDataSources.length === 1 ? (
            <div className="flex cursor-pointer items-center gap-1" onClick={replace}>
              <span className="text-xs font-medium text-brand-800">Replace</span>
              <Replace width={16} height={16} className="fill-brand-800" />
            </div>
          ) : (
            <div className="flex cursor-pointer items-center gap-1" onClick={remove}>
              <span className="text-xs font-medium text-brand-800">Remove</span>
              <CloseCircle width={16} height={16} className="fill-brand-800" />
            </div>
          )}
        </div>
      </div>
      {setupInterrupted && (
        <div className="flex gap-2 rounded-md bg-red-50 px-6 py-4.5">
          <Warning width={20} height={20} className="fill-orange-600" />
          <span className="text-sm text-red-600">Set up Data Source</span>
        </div>
      )}
      {state.expanded && (
        <div className="flex flex-col gap-6">
          {authType !== DataSourceAuth.NONE && (
            <DataSourceAuthentication
              dataSourceId={dataSource.id!}
              projectId={project!.id!}
              dataSource={dataSource.name!}
              website={dataSource.website!}
              accountDataType={authType}
              authenticated={state.authenticated}
              configured={state.configured}
              setAuthenticated={authenticate}
              note={
                <>
                  In order to connect to this Data Source you need to <strong>enter credentials</strong>.
                </>
              }
            />
          )}
          {(state.authenticated || authType === DataSourceAuth.NONE) && (
            <div className="flex flex-col gap-6">
              <Divider />
              {!state.configured ? (
                <ConfigurationForm dataSource={dataSource} state={state} />
              ) : (
                <ConfigurationDisplay dataSource={dataSource} state={state} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default DataSourceActivationCard;
