import StatusEnum from 'constants/enums/status';
import { ProjectDeploymentSchema } from 'lib/model';

export function getDeploymentStatus(deployment: ProjectDeploymentSchema) {
  if (deployment.exit_status === 'FAILED') {
    return StatusEnum.ERROR;
  }else if (deployment.exit_status === 'UNKNOWN') {
    return StatusEnum.UNKNOWN;
  } else if (deployment.exit_status === 'SUCCEEDED') {
    return StatusEnum.SUCCESS;
  } else if (!deployment.time_finished) {
    return StatusEnum.RUNNING;
  } else {
    return StatusEnum.UNKNOWN;
  }
}
