import Axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';

const axios = Axios.create({
  baseURL: import.meta.env.VITE_BASE_URL
});

function forceLogout() {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('active_project');
  if (!window.location.pathname.startsWith('/login')) {
    window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`;
  }
}

// Look into axios-auth-refresh for a more robust solution
// https://www.npmjs.com/package/axios-auth-refresh
// Currently if multiple requests are made at the same time, they will all try to refresh the token
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (originalRequest.url === '/user' && error.response?.status === 404) {
      // If current user doesn't exist on this project force log out
      forceLogout();
    }
    if (error.response && error.response.status === 401) {
      const refresh_token = localStorage.getItem('refresh_token');
      try {
        // If refresh token endpoint returns 401, then the refresh token is invalid and the user should be logged out
        if (
          refresh_token === null ||
          originalRequest.url === '/user/get_refresh_token' ||
          originalRequest.url === '/user/login'
        ) {
          throw error;
        }

        // If another endpoint returns 401, then the access token is invalid and should be refreshed
        const { token: newAccessToken, refresh_token: newRefreshToken } = await axios
          .get('/user/get_refresh_token', { headers: { Authorization: 'Bearer ' + refresh_token } })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error;
          });

        localStorage.setItem('token', newAccessToken);
        localStorage.setItem('refresh_token', newRefreshToken);
        error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axios(error.config);
      } catch {
        forceLogout();
      }
    }
    return Promise.reject(error);
  }
);

export default async function customInstance<T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> {
  const token = localStorage.getItem('token');
  if (token) config.headers = new AxiosHeaders({ Authorization: 'Bearer ' + token });
  const { data } = await axios({ ...config, ...options });
  return data;
}
