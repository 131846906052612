/* eslint-disable @typescript-eslint/no-explicit-any */
import { PaginationState } from '@tanstack/react-table';
import Previous from 'assets/chevron-left.svg?react';
import Next from 'assets/chevron-right.svg?react';
import { defaultPageSize } from 'constants/tableDefaults';
import { memo, useCallback } from 'react';
import { twJoin } from 'tailwind-merge';

interface PaginationProps {
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  rowCount?: number;
}

const PaginationWithState = memo(function Pagination({ rowCount, pagination, setPagination }: PaginationProps) {
  const { pageIndex, pageSize } = pagination;

  const handlePrevious = useCallback(
    function handlePrevious() {
      setPagination({ ...pagination, pageIndex: pageIndex - 1 });
    },
    [pagination, pageIndex, setPagination]
  );

  const handleNext = useCallback(
    function handleNext() {
      setPagination({ ...pagination, pageIndex: pageIndex + 1 });
    },
    [pagination, pageIndex, setPagination]
  );

  const handleLast = useCallback(
    function handleLast() {
      setPagination({
        ...pagination,
        pageIndex: Math.max(Math.floor((rowCount! - 1) / Number(pageSize ?? defaultPageSize)), 0)
      });
    },
    [pagination, rowCount, pageSize, setPagination]
  );

  const handleFirst = useCallback(
    function handleFirst() {
      setPagination({ ...pagination, pageIndex: 0 });
    },
    [pagination, setPagination]
  );

  const handleNavigate = useCallback(
    function handleNavigate(page: number) {
      return () => {
        setPagination({ ...pagination, pageIndex: page });
      };
    },
    [setPagination, pagination]
  );

  if (rowCount === 0) {
    return null;
  }

  if (!rowCount) {
    return <PaginationSkeleton />;
  }

  const lastPage = Math.max(Math.floor((rowCount! - 1) / Number(pageSize ?? defaultPageSize)), 0);

  const canGetPrevious = pageIndex > 0;
  const canGetNext = pageIndex < lastPage;

  // the goal is to have 9 buttons on screen at all times if possible
  // spread 4 from current
  let start = Math.max(0, pageIndex - 4);
  let end = Math.min(lastPage, pageIndex + 4);

  // if near first page or last page, transfer reminder to other side
  const startReminder = 4 + start - pageIndex;
  const endReminder = 4 + pageIndex - end;

  // add reminder, but don't go out of borders
  start = Math.max(0, start - endReminder);
  end = Math.min(lastPage, end + startReminder);

  // if too far from border, show border button
  const showFirst = pageIndex - 4 > 0;
  const showLast = pageIndex + 4 < lastPage;

  // if showing border button, reduce that side by 2
  if (showFirst) {
    start += 2;
  }
  if (showLast) {
    end -= 2;
  }

  const pages = Array.from({ length: end - start + 1 }, (_, i) => i + start);
  if (pages.length === 1) {
    return null;
  }

  return (
    <div className="flex items-center justify-center gap-2 p-6">
      {pages.length > 1 && (
        <button
          disabled={!canGetPrevious}
          onClick={handlePrevious}
          className="flex size-10 items-center justify-center rounded-md bg-brand-800 disabled:bg-brand-950"
        >
          <Previous width={24} height={24} className="fill-white" />
        </button>
      )}
      {showFirst && (
        <>
          <button
            onClick={handleFirst}
            className="flex size-10 items-center justify-center bg-white text-xs font-semibold text-gray-600"
          >
            1
          </button>
          <span className="flex size-10 items-center justify-center bg-white text-xs font-semibold text-gray-600">
            ...
          </span>
        </>
      )}
      {pages.map((page) => (
        <button
          onClick={handleNavigate(page)}
          key={page}
          className={twJoin(
            'flex h-10 w-10 items-center justify-center rounded-md',
            pageIndex === page ? 'border border-brand-800 bg-brand-50' : 'bg-white'
          )}
        >
          <span className={twJoin('text-xs font-semibold', pageIndex === page ? 'text-brand-800' : 'text-gray-600')}>
            {page + 1}
          </span>
        </button>
      ))}
      {showLast && (
        <>
          <span className="flex size-10 items-center justify-center bg-white text-xs font-semibold text-gray-600">
            ...
          </span>
          <button
            onClick={handleLast}
            className="flex size-10 items-center justify-center bg-white text-xs font-semibold text-gray-600"
          >
            {lastPage + 1}
          </button>
        </>
      )}
      {pages.length > 1 && (
        <button
          disabled={!canGetNext}
          onClick={handleNext}
          className="flex size-10 items-center justify-center rounded-md bg-brand-800 disabled:bg-brand-950"
        >
          <Next width={24} height={24} className="fill-white" />
        </button>
      )}
    </div>
  );
});

export default PaginationWithState;

const PaginationSkeleton = memo(function PaginationSkeleton() {
  return (
    <div className="flex items-center justify-center gap-2 p-6">
      <div className="size-10 animate-pulse rounded-md bg-gray-100" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-50" />
      <div className="size-10 animate-pulse rounded-md bg-gray-100" />
    </div>
  );
});
