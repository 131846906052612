import { useQueryClient } from '@tanstack/react-query';
import Back from 'assets/arrow-left.svg?react';
import More from 'assets/chevron-right.svg?react';
import { reportPermissionOptions } from 'constants/formOptions';
import useAuth from 'contexts/auth/authContext';
import {
  ProjectRoleSchemaName,
  ProjectRolesSchema,
  ReportAccessCreate,
  ReportAccessCreatePermission,
  ReportNestedSchema
} from 'lib/model';
import { useGetProjectProjectIdUser } from 'lib/project-user/project-user';
import { useGetReportReportIdUsers, usePostReportReportIdUsers } from 'lib/report/report';
import Button from 'modules/common/Button';
import CircleImage from 'modules/common/CircleImage';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { isActiveUser, getUserInitials } from 'utils/helpers';
import { AutocompleteOption, DialogProps, ValueOption } from 'utils/interfaces';
import ShareReportUserInput from './ShareReportUserInput';
import SharedWithItem from './SharedWithItem';
import { projectUserToOption } from 'utils/mappings';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';

interface ShareReportInviteFormValues {
  users: AutocompleteOption[] | null;
  permission: ValueOption | null;
}

interface ShareReportDialogProps extends DialogProps {
  report: ReportNestedSchema;
}

enum ShareReportStepsEnum {
  INVITE,
  EDIT
}

function ShareReportDialog({ open, setOpen, report }: ShareReportDialogProps) {
  const [step, setStep] = useState<ShareReportStepsEnum>(ShareReportStepsEnum.INVITE);
  const { reset, handleSubmit, control, formState, watch, setError, clearErrors } =
    useForm<ShareReportInviteFormValues>({
      defaultValues: {
        permission: reportPermissionOptions.find((x) => x.id === ReportAccessCreatePermission.read)
      }
    });
  const [grantedPermissionOptions, setGrantedPermissionOptions] = useState(reportPermissionOptions);

  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const projectRoles = queryClient.getQueryData<ProjectRolesSchema>(['project', project.id!, 'roles']);
  const { user: currentUser } = useAuth();
  const reportUsers = useGetReportReportIdUsers(report.id!, {
    query: {
      queryKey: ['reportUsers', report.id],
      enabled: !!report?.id,
      staleTime: Infinity
    }
  });
  const projectUsers = useGetProjectProjectIdUser(report.project!, {
    query: {
      queryKey: ['projectUsers', report.project],
      enabled: !!report?.project,
      staleTime: Infinity
    }
  });
  const projectUserOptions = useMemo(() => {
    return (
      projectUsers.data?.objects
        ?.filter((user) => !reportUsers.data?.users!.some((reportUser) => reportUser.user_id === user.user_account_id))
        .filter((user) => user.user_account_id !== currentUser.id)
        .filter(isActiveUser)
        .map((user) => projectUserToOption(user, projectRoles)) ?? []
    );
  }, [currentUser.id, projectUsers.data?.objects, reportUsers.data, projectRoles]);

  const { mutate: shareReport, isPending: isSharingReport } = usePostReportReportIdUsers({
    mutation: {
      onSuccess: () => {
        toast.success('Report has been successfully shared');
        queryClient.invalidateQueries({ queryKey: ['reportUsers'] });
        queryClient.invalidateQueries({ queryKey: ['reports'] });
        reset();
      },
      onError: (error: AxiosError) => {
        const data = error.response?.data as { error: string };
        if (data.error) {
          toast.error(data.error);
        }
      }
    }
  });

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const sendReport = useCallback(
    function sendReport(data: ShareReportInviteFormValues) {
      const users: ReportAccessCreate[] =
        data.users?.map((option) => {
          if (option.secondaryLabel) {
            return {
              user_id: option.id as string,
              user_email: option.secondaryLabel,
              permission: data.permission!.id as ReportAccessCreatePermission
            };
          } else {
            return {
              user_email: option.label,
              permission: data.permission!.id as ReportAccessCreatePermission
            };
          }
        }) ?? [];

      shareReport({
        reportId: report.id!,
        data: { users }
      });
      return data;
    },
    [report.id, shareReport]
  );

  function toggleEdit() {
    if (step === ShareReportStepsEnum.INVITE) {
      setStep(ShareReportStepsEnum.EDIT);
    } else {
      setStep(ShareReportStepsEnum.INVITE);
    }
  }

  useEffect(() => {
    const subscription = watch(({ users }) => {
      if (
        users?.find((x) => x!.value === ProjectRoleSchemaName.viewer || x!.value === ProjectRoleSchemaName.scout_viewer)
      ) {
        setGrantedPermissionOptions(reportPermissionOptions.filter((x) => x.id === ReportAccessCreatePermission.read));
      } else {
        setGrantedPermissionOptions(reportPermissionOptions);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const permission = watch('permission');

  return (
    <DialogBase title="Share Report" onCancel={handleCancel} open={open}>
      {step === ShareReportStepsEnum.INVITE && (
        <>
          <DialogContent>
            {reportUsers.data?.users && reportUsers.data.users.length > 0 && (
              <div className="flex flex-col gap-6">
                <span className="text-md font-semibold">Shared with</span>
                <button onClick={toggleEdit} className="flex items-center justify-between gap-6 bg-gray-50 px-3 py-2.5">
                  <div className="flex min-h-9 flex-wrap items-center gap-4">
                    {reportUsers.data?.users?.slice(0, 3).map((user) => (
                      <div className="flex items-center gap-2" key={user.user_email}>
                        <CircleImage
                          image={user.user_image_path}
                          text={user.first_name ? getUserInitials(user) : user.user_email!.charAt(0)}
                          size="size-9"
                        />
                        <span className="truncate text-sm font-medium">
                          {user.first_name ? `${user.first_name} ${user.last_name}` : user.user_email}
                        </span>
                      </div>
                    ))}
                    {reportUsers.data.users.length > 3 && (
                      <span className="truncate text-sm font-medium">+{reportUsers.data?.users.length - 3}</span>
                    )}
                  </div>

                  <More className="size-6 fill-gray-700" />
                </button>
              </div>
            )}
            <form className="flex flex-col gap-6" onSubmit={handleSubmit(sendReport)} id="share-report-form">
              <span className="text-md font-semibold">Invite users</span>
              <div
                className={
                  'flex gap-6 max-sm:flex-wrap [&>:first-child]:basis-3/4 max-sm:[&>:first-child]:basis-full [&>:last-child]:basis-1/4 max-sm:[&>:last-child]:basis-full'
                }
              >
                <ShareReportUserInput
                  name="users"
                  control={control}
                  options={projectUserOptions}
                  label="Enter emails or pick existing users"
                  placeholder="Ex: user.name@gmail.com, user.name@yahoo.com"
                  loading={projectUsers.isPending}
                  error={formState.errors.users}
                  setInputError={setError}
                  clearInputError={clearErrors}
                  allowViewers={permission?.id === 'read'}
                />
                <SelectInput
                  formProps={{
                    control: control,
                    name: 'permission',
                    rules: { required: { value: true, message: 'Permission is required' } }
                  }}
                  options={grantedPermissionOptions}
                  label="Permission"
                  error={formState.errors.permission}
                />
              </div>
            </form>
          </DialogContent>
          <DialogFooter>
            <Button variant="secondary" size="xl" onClick={handleCancel} disabled={isSharingReport}>
              Cancel
            </Button>
            <Button size="xl" isSubmitButton form="share-report-form" loading={isSharingReport}>
              Invite Users
            </Button>
          </DialogFooter>
        </>
      )}
      {step === ShareReportStepsEnum.EDIT && (
        <DialogContent>
          <div className="flex items-center gap-3">
            <button onClick={toggleEdit}>
              <Back className="size-6 fill-gray-700" />
            </button>
            <span className="text-md font-semibold">Shared with: {reportUsers.data?.users?.length ?? 0} users</span>
          </div>
          <div className="flex flex-col gap-2">
            {reportUsers.data?.users?.map?.((user) =>
              user.invite_sent ? (
                <div
                  key={user.user_email}
                  className="flex h-14 items-center justify-between gap-2.5 rounded-md border border-dashed border-gray-400 px-3 py-2"
                >
                  <span className="text-sm font-semibold">{user.user_email}</span>
                  <span className="text-sm font-medium text-gray-600">Invite sent</span>
                </div>
              ) : (
                <SharedWithItem key={user.user_email} user={user} report={report} />
              )
            )}
          </div>
        </DialogContent>
      )}
    </DialogBase>
  );
}

export default memo(ShareReportDialog);
