import { keepPreviousData } from '@tanstack/react-query';
import useActiveProject from 'contexts/project/projectContext';
import { useGetProjectConfigurationProjectConfigurationIdDatasourceConfiguration } from 'lib/datasource-configuration/datasource-configuration';
import { memo, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import DataSourceCardGridSkeleton from '../components/DataSourceCardGridSkeleton';
import ConnectedDataSourceCard from './components/ConnectedDataSourceCard';
import PriorityOrderCard from './components/PriorityOrderCard';

const ConnectedDataSourcesPage = memo(function ConnectedDataSourcesPage() {
  const { project } = useActiveProject();
  const { data: dataSourceConfigurations, isFetching: isFetchingDataSourceConfigurations } =
    useGetProjectConfigurationProjectConfigurationIdDatasourceConfiguration(project!.project_configuration_latest!, {
      query: {
        queryKey: [
          'project',
          project.id,
          'configuration',
          project.project_configuration_latest,
          'datasource-configuration'
        ],
        staleTime: Infinity,
        enabled: !!project!.project_configuration_latest,
        placeholderData: keepPreviousData
      }
    });

  const active = useMemo(
    () => dataSourceConfigurations?.objects?.filter((dsc) => dsc.is_active) ?? [],
    [dataSourceConfigurations?.objects]
  );
  const inactive = useMemo(
    () => dataSourceConfigurations?.objects?.filter((dsc) => !dsc.is_active) ?? [],
    [dataSourceConfigurations?.objects]
  );

  if (!project?.project_configuration_latest || dataSourceConfigurations?.objects?.length === 0) {
    return <Navigate to={'/data-sources/all'} />;
  }

  return (
    <>
      {!isFetchingDataSourceConfigurations ? (
        <>
          <div className="flex w-full gap-6 max-lg:flex-col">
            <div className="flex w-full flex-col gap-6 pt-2 max-md:px-0">
              {active.length > 0 && (
                <>
                  <span className="text-sm font-semibold text-gray-800">Active: {active.length}</span>
                  <div className="grid w-full grid-cols-2 gap-6 max-lg:grid-cols-1 min-[2000px]:grid-cols-3">
                    {active.map((dsc) => (
                      <ConnectedDataSourceCard dataSourceConfiguration={dsc} key={dsc.id} />
                    ))}
                  </div>
                </>
              )}
              {inactive.length > 0 && (
                <>
                  <span className="text-sm font-semibold text-gray-800">Inactive: {inactive.length}</span>
                  <div className="grid w-full grid-cols-2 gap-6 max-lg:grid-cols-1 min-[2000px]:grid-cols-3">
                    {inactive.map((dsc) => (
                      <ConnectedDataSourceCard dataSourceConfiguration={dsc} key={dsc.id} />
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="h-fit w-[320px] max-lg:w-full lg:sticky lg:top-[186px] lg:mt-[54px]">
              <PriorityOrderCard />
            </div>
          </div>
        </>
      ) : (
        <DataSourceCardGridSkeleton />
      )}
    </>
  );
});

export default ConnectedDataSourcesPage;
