import { useQueryClient } from '@tanstack/react-query';
import ChartData from 'assets/chart-data.svg?react';
import CustomerSupport from 'assets/customer-support.svg?react';
import Dashboard from 'assets/dashboard.svg?react';
import Database from 'assets/database.svg?react';
import Report from 'assets/report.svg?react';
import Settings from 'assets/settings.svg?react';
import useAuth from 'contexts/auth/authContext';
import useActiveProject from 'contexts/project/projectContext';
import { useGetProjectProjectIdDashboardDatasourcesCount } from 'lib/dashboard/dashboard';
import { DataTablesSchema, ProjectGetSchema } from 'lib/model';
import { useMemo } from 'react';
import { NavigationItem } from 'utils/interfaces';

export default function useAppNavigation() {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { project } = useActiveProject();
  const dataTables = queryClient.getQueryData<DataTablesSchema>(['project', project.id, 'data-tables'])!;

  const { data: dataSourcesCount } = useGetProjectProjectIdDashboardDatasourcesCount(project.id!, {
    query: { queryKey: ['project', project.id, 'data-source', 'count'], enabled: !!project.id, staleTime: Infinity }
  });
  const hasConnectedDataSource = dataSourcesCount ? dataSourcesCount.count > 0 : false;

  const primaryRoutes = useMemo(
    (): NavigationItem[] => [
      { name: 'Dashboard', href: '/', Icon: Dashboard, isActive: (path: string) => path === '/' },
      {
        name: 'Data Sources',
        href: `/data-sources/${hasConnectedDataSource ? 'connected' : 'all'}`,
        Icon: Database,
        isActive: (path: string) => path.startsWith('/data-sources/')
      },
      {
        name: 'Data Tables',
        href: `/data-tables/${dataTables.objects?.at(0)?.id}`,
        Icon: ChartData,
        isActive: (path: string) => path.startsWith('/data-tables/')
      },
      { name: 'Reports', href: '/reports', Icon: Report, isActive: (path: string) => path.startsWith('/reports') }
    ],
    [hasConnectedDataSource, dataTables]
  );

  const secondaryRoutes: NavigationItem[] = [
    {
      name: 'Settings',
      href: '/settings/account',
      Icon: Settings,
      isActive: (path: string) => path.startsWith('/settings')
    },
    {
      name: 'Customer support',
      href: '/customer-support',
      Icon: CustomerSupport,
      isActive: (path: string) => path === '/customer-support'
    }
  ];

  return { project, user, primaryRoutes, secondaryRoutes };
}
