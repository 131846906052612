import { useQueryClient } from '@tanstack/react-query';
import Trash from 'assets/trash-remove.svg?react';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';
import { getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions } from 'lib/datasource-configuration/datasource-configuration';
import {
  DatasourceConfigurationsNestedSchema,
  ProjectConfigurationCreateSchema,
  ProjectConfigurationNestedSchema,
  ProjectGetSchema
} from 'lib/model';
import {
  getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions,
  useDeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
} from 'lib/project-configuration/project-configuration';
import Button from 'modules/common/Button';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DataSourceDialogProps } from 'utils/interfaces';

const RemoveDataSourceDialog = memo(function RemoveDataSourceDialog({
  open,
  setOpen,
  dataSource,
  dataSourceConfiguration
}: DataSourceDialogProps) {
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { mutate: deleteDataSourceConfiguration } =
    useDeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId();

  function handleRemove() {
    setLoading(true);
    deleteDataSourceConfiguration(
      {
        datasourceConfigurationId: dataSourceConfiguration.id!,
        projectConfigurationId: project.project_configuration_latest!
      },
      {
        onSuccess: async (result: ProjectConfigurationCreateSchema) => {
          queryClient.removeQueries({
            queryKey: ['project', project.id, 'configuration', project.project_configuration_latest]
          });

          queryClient.invalidateQueries({ queryKey: ['project', project.id, 'data-sources', 'count'] });
          await queryClient.invalidateQueries({ queryKey: ['project', project.id], exact: true, refetchType: 'all' });
          // Fetch data source configurations
          await queryClient
            .fetchQuery(
              getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions(result.id!, {
                query: {
                  queryKey: ['project-configuration', result.id, 'datasource-configuration'],
                  staleTime: Infinity
                }
              })
            )
            .then((res: DatasourceConfigurationsNestedSchema) => {
              if (res.objects?.length === 0) navigate('/data-sources/all');
            });

          // Start project configuration refetch in the background
          queryClient.prefetchQuery(
            getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions(
              result.project!,
              result.id!,
              { nested: true },
              {
                query: {
                  queryKey: ['project', result.project, 'configuration', result.id],
                  staleTime: Infinity
                }
              }
            )
          );

          setLoading(false);
          setOpen(false);
          toast.success('Data source removed successfully.');
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response?.data.error;
            toast.error(error);
          }
          setLoading(false);
        }
      }
    );
  }

  function handleCancel() {
    setOpen(false);
  }

  return (
    <DialogBase title="Remove Data Source" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <div className="flex h-36 items-center justify-center">
          <Trash width={64} height={64} className="fill-brand-800" />
        </div>
        <div className="flex items-center bg-gray-50 p-3">
          <DataSourceTitle name={dataSource.name!} image={dataSource?.logo_image_path} />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-sm">
            Removing this data source will eliminate it from your subscription. Please note that you cannot replace it
            with a new one for the next 45 days, but you can reinstate it during that time. Any data linked to this
            source, including associated reports, will be removed from the platform.
          </span>
          <span className="text-sm">Are you sure you want to proceed with removal?</span>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="red" size="lg" onClick={handleRemove} loading={loading}>
          Remove
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default RemoveDataSourceDialog;
