import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import GenericTable from 'modules/common/GenericTable';
import { useMemo, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import SubscriptionCell from './components/table/SubscriptionCell';
import DashboardProjectCard from './components/DashboardProjectCard';
import { AdminProjectSchema } from './interfaces';

import ChevronDown from 'assets/chevron-down.svg?react';
import ChevronUp from 'assets/chevron-up.svg?react';
import Report from 'assets/report.svg?react';
import StatusCell from './components/table/StatusCell';
import UsersCell from './components/table/UsersCell';
import PaginationWithState from 'modules/common/PaginationWithState';

interface ProjectsTableProps {
  tableData: AdminProjectSchema[];
}

const renderSubComponent = ({ row }: { row: Row<AdminProjectSchema> }) => {
  return <DashboardProjectCard project={row.original} reports={row.original.reports} />;
};

const HeaderCell = ({ title }: { title: string }) => {
  return <div className={twJoin('mx-2 text-tiny font-medium uppercase text-gray-500 max-md:hidden')}>{title}</div>;
};

const ProjectsTable = ({ tableData }: ProjectsTableProps) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const columnHelper = createColumnHelper<AdminProjectSchema>();
  const columns = useMemo(() => {
    const columns: ColumnDef<AdminProjectSchema>[] = [
      columnHelper.accessor('organization_name', {
        id: 'organization',
        header: () => <HeaderCell title="ORGANIZATION" />,
        cell: (info) => {
          return (
            <div className="mx-3 ml-6 flex items-center gap-2">
              <span className={'text-wrap text-xs font-medium'}>{info.renderValue()}</span>
            </div>
          );
        }
      }),
      columnHelper.display({
        id: 'users',
        header: () => <HeaderCell title="USERS" />,
        cell: ({ row }) => {
          return <UsersCell project={row.original} />;
        }
      }),
      columnHelper.accessor('reports', {
        id: 'reports',
        header: () => <HeaderCell title="REPORTS" />,
        cell: ({ getValue }) => {
          const reports = getValue();
          return (
            <div className="flex gap-1 text-xs">
              <Report className="size-5 fill-gray-700" /> {reports.length}
            </div>
          );
        }
      }),
      columnHelper.display({
        id: 'subscription',
        header: () => <HeaderCell title="SUBSCRIPTION" />,
        cell: ({ row }) => <SubscriptionCell project={row.original} />
      }),
      columnHelper.display({
        id: 'aws-cost',
        header: () => <HeaderCell title="AWS" />,
        cell: ({ row }) => {
          return <div className="text-xs">[PH] 1 credits</div>;
        }
      }),
      columnHelper.display({
        id: 'status',
        header: () => <HeaderCell title="STATUS" />,
        cell: ({ row }) => {
          return <StatusCell project={row.original} />;
        }
      }),
      columnHelper.display({
        id: 'expander',
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <button
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' }
              }}
            >
              {row.getIsExpanded() ? (
                <ChevronUp width={24} height={24} className="fill-gray-800" />
              ) : (
                <ChevronDown width={24} height={24} className="fill-gray-800" />
              )}
            </button>
          ) : (
            '🔵'
          );
        }
      })
    ];
    return columns;
  }, [columnHelper]);

  const table = useReactTable({
    data: tableData ?? [],
    columns,
    state: {
      pagination,
      columnPinning: {
        right: ['expander']
      }
    },
    getRowCanExpand: ({ original }) => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    renderFallbackValue: '-'
  });

  return (
    <>
      <GenericTable table={table} isDataFetching={false} renderSubComponent={renderSubComponent} />
      <PaginationWithState pagination={pagination} setPagination={setPagination} rowCount={tableData.length} />
    </>
  );
};

export default ProjectsTable;
