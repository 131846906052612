import DataSourceAuth from 'constants/enums/dataSourceAuth';
import { seasonOptions } from 'constants/generated';
import {
  DatasourceConfigurationNestedSchema,
  DatasourceSchema,
  ProjectUserSchema,
  ReportAccessSchema,
  UserAccountSchema
} from 'lib/model';
import { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ValueOption } from './interfaces';

const numberUnitsOfSize = ['K', 'M', 'B', 'T', 'Q'];

export function toShortNumber(number: number): string {
  if (number === undefined) {
    return '';
  }
  let scale = -1;
  while (number > 1000) {
    number /= 1000;
    scale++;
  }
  if (scale === -1) {
    return String(number);
  } else {
    return number.toFixed(1) + ' ' + numberUnitsOfSize[scale];
  }
}

export function kebabCaseToWords(kebabCaseString: string): string {
  return kebabCaseString
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function snakeCaseToWords(snakeCaseString: string): string {
  return snakeCaseString
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function capitalizeWord(s: string): string {
  return s[0].toUpperCase() + s.slice(1);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringEnumToOptions(e: any): ValueOption[] {
  const values = Object.values(e) as string[];
  return values.map((value: string) => ({
    id: value,
    label: kebabCaseToWords(value)
  }));
}

export function getSelectedLeaguesFromDataSourceConfig(
  config: DatasourceConfigurationNestedSchema,
  dataSource: DatasourceSchema
): ValueOption[] {
  return config.datasource_configuration_competitions!.map((c) => {
    return {
      label:
        dataSource.datasource_competitions?.find((x) => x.id! === c.datasource_competition_id!)?.description ??
        c.datasource_competition_id!,
      id: c.datasource_competition_id!
    };
  });
}

export function getSelectedAreasFromDataSourceConfig(
  config: DatasourceConfigurationNestedSchema,
  dataSource: DatasourceSchema
): ValueOption[] {
  return config.datasource_configuration_areas!.map((c) => {
    return {
      label: dataSource.datasource_areas?.find((x) => x.id! === c.datasource_area_id!)?.name ?? c.datasource_area_id!,
      id: c.datasource_area_id!
    };
  });
}

export function getMinimumStartSeasonFromDataSourceConfig(config: DatasourceConfigurationNestedSchema) {
  let min_seas: number | undefined = undefined;
  if (config.datasource_configuration_competitions && config.datasource_configuration_competitions.length > 0) {
    min_seas = config.datasource_configuration_competitions[0].start_season;
  } else if (config.datasource_configuration_areas && config.datasource_configuration_areas.length > 0) {
    min_seas = config.datasource_configuration_areas[0].start_season;
  }

  return min_seas ? seasonOptions.find((x) => x.id === String(min_seas)) : undefined;
}

export function getSeasonsFromDataSourceConfig(config: DatasourceConfigurationNestedSchema) {
  if (config.datasource_configuration_competitions!.length === 0) return;
  const startSeason = config.datasource_configuration_competitions![0].start_season!;
  return seasonOptions.filter((x) => Number(x.id) >= startSeason);
}

export function getDataSourceAuthType(dataSource: DatasourceSchema) {
  const authOptions = dataSource.datasource_options!.filter((x) => x.authentication);
  return authOptions.length
    ? authOptions.length === 1
      ? DataSourceAuth.API_KEY
      : DataSourceAuth.EMAIL_AND_PASSWORD
    : DataSourceAuth.NONE;
}

export function isLightColor(color: string): boolean {
  if (!color) {
    return false;
  }
  if (color[0] === '#') {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 2), 16);
    const c_g = parseInt(hex.substring(2, 4), 16);
    const c_b = parseInt(hex.substring(4, 6), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 170;
  }
  if (color[0] === 'r') {
    const [c_r, c_g, c_b] = color
      .substring(color.indexOf('(') + 1, color.indexOf(')'))
      .split(',')
      .map((numberString) => Number(numberString));
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 170;
  }
  if (color === 'white') {
    return true;
  }
  return false;
}

export function isWhite(color: string): boolean {
  return color.toLowerCase() === '#ffffff' || color === 'rgb(255, 255, 255)' || color === 'white';
}

export function utf8_to_b64(text: string) {
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(text);
  let binaryString = '';
  uint8Array.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  return window.btoa(binaryString);
}

export const downloadCSV = (csvData: string, csvName: string) => {
  const url = 'data:text/csv;charset=utf-8;base64,' + utf8_to_b64(csvData);
  const a = document.createElement('a');
  a.download = csvName;
  a.target = '_blank';
  a.href = url;
  a.click();
};

export function conditionallyRender(render: boolean, word?: string | number) {
  if (render) return ' ' + word;
  return '';
}

export function stopPropagation(e: React.MouseEvent<unknown, MouseEvent>) {
  e.stopPropagation();
}

export function Portal({ children }: PropsWithChildren) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  if (!mounted) return null;
  return createPortal(children, document.body);
}

export function getUserInitials(user: ProjectUserSchema | UserAccountSchema | ReportAccessSchema) {
  if (user.first_name && user.last_name) {
    return user.first_name!.charAt(0) + user.last_name!.charAt(0);
  } else {
    return '--';
  }
}

export function getInitials(name?: string): string {
  if (!name) return '';
  return name.replace(/[a-z]/g, '');
}

export function doNothing() {
  return null;
}

export function isActiveUser(user: ProjectUserSchema) {
  return user.first_name !== null && user.last_name !== null && user.user_account_id !== null;
}

// AWS Event Bridge format 
// https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-scheduled-rule-pattern.html
// Example: 0 0 19 * ? *
// Example: 0 1 ? * FRI *
// minute hour dayOfMonth month dayOfWeek year

// https://www.npmjs.com/package/aws-cron-parser

// Minute: 0-59 or *
// Hour: 0-23 or *
// Day of month: 1-31, ?, L, LW, W
// Month: 1-12, JAN-DEC
// Day of week: 1-7, SUN-SAT, ?, L, #, -
// Year: 1970-2199 or *
export function parseAWSCronToDate(cronString: string): Date | null {
  const cronParts = cronString.split(' ');
  if (cronParts.length !== 6) {
    console.error('Invalid cron string format');
    return null;
  }

  const [minute, hour, dayOfMonth, month, dayOfWeek, year] = cronParts;

  const now = new Date();
  const nextDate = new Date(
    year === '*' ? now.getFullYear() : parseInt(year),
    month === '*' ? now.getMonth() : parseInt(month) - 1,
    dayOfMonth === '*' ? now.getDate() : parseInt(dayOfMonth),
    hour === '*' ? now.getHours() : parseInt(hour),
    minute === '*' ? now.getMinutes() : parseInt(minute),
    0
  );

  // TODO: Improve handling if next date is in the past
  if (nextDate < now) {
    nextDate.setFullYear(nextDate.getFullYear() + 1);
  }

  return nextDate;
};
