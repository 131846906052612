import User from 'assets/user.svg?react';
import useAuth from 'contexts/auth/authContext';
import { useGetProject } from 'lib/project/project';
import { useGetReport } from 'lib/report/report';
import { useGetAdminDatasourceConfigurationsLatest, useGetAdminDeploymentsLatest } from 'lib/admin/admin';
import { useMemo } from 'react';
import { ReportSchema } from 'lib/model';
import ProjectsTable from './ProjectsTable';
import DashboardAdminSummary from './components/DashboardAdminSummary';

const DashboardAdmin = () => {
  const { user } = useAuth();

  const { data: deploymentsResponse } = useGetAdminDeploymentsLatest({
    query: { queryKey: ['admin', 'deployments', 'latest'], enabled: !!user.is_admin, staleTime: Infinity }
  });
  const latestDeployments = useMemo(() => {
    return deploymentsResponse?.objects ?? [];
  }, [deploymentsResponse]);

  const { data: datasourceConfigurationResponse } = useGetAdminDatasourceConfigurationsLatest({
    query: { queryKey: ['admin', 'datasource-configuration', 'latest'], enabled: !!user.is_admin, staleTime: Infinity }
  });
  const datasourceConfigurations = useMemo(() => {
    return datasourceConfigurationResponse?.objects ?? [];
  }, [datasourceConfigurationResponse]);

  const { data: projectData } = useGetProject(undefined, {
    query: { queryKey: ['projects'], enabled: !!user.is_admin, staleTime: Infinity }
  });

  // Project reports
  const { data: reportsData } = useGetReport(
    {},
    {
      query: {
        queryKey: ['reports'],
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false
      }
    }
  );

  const projectReports = useMemo(() => {
    const reports = reportsData?.objects;
    if (!reports) {
      return {};
    }

    const projectReports = reports.reduce(
      (acc, report: ReportSchema) => {
        const projectId = report.project!;
        if (!acc[projectId]) {
          acc[projectId] = [report];
        } else {
          acc[projectId].push(report);
        }
        return acc;
      },
      {} as { [key: string]: ReportSchema[] }
    );

    return projectReports;
  }, [reportsData]);

  const projects = useMemo(() => {
    if (!projectData?.objects || !projectReports) return [];

    return (
      projectData.objects.map((project) => {
        return {
          ...project,
          reports: projectReports[project.id!] ?? []
        };
      }) ?? []
    );
  }, [projectData, projectReports]);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-3">
          <User className="size-5 fill-gray-700" />
          <span className="text-sm font-semibold">Admin Dashboard</span>
        </div>
      </div>
      <DashboardAdminSummary />
      <div>
        <ProjectsTable tableData={projects} />
      </div>
    </div>
  );
};

export default DashboardAdmin;
