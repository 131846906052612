import { memo } from 'react';
import DashboardActivity from './components/Activity/DashboardActivity';
import DashboardHeader from './components/DashboardHeader';
import DashboardDataSources from './components/DataSources/DashboardDataSources';
import DashboardReports from './components/Reports/DashboardReports';
import DashboardSubscriptionUsage from './components/SubscriptionUsage/DashboardSubscriptionUsage';
import useAuth from 'contexts/auth/authContext';
import DashboardAdmin from './components/Admin/DashboardAdmin';

function DashboardPage() {
  const { user } = useAuth();
  return (
    <div className="flex w-full flex-col items-center">
      <DashboardHeader />
      <div className="flex w-full justify-center gap-6 max-lg:flex-col">
        <div className="flex w-full flex-col gap-10">
          {user.is_admin && <DashboardAdmin />}
          <DashboardSubscriptionUsage />
          <DashboardDataSources />
          <DashboardReports />
        </div>
        <DashboardActivity />
      </div>
    </div>
  );
}

export default memo(DashboardPage);
