import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DefaultConfigurationFormValues } from 'constants/defaultValues';
import { dataSourceRefreshFrequencyOptions } from 'constants/formOptions';
import { CRONTimeOptions } from 'constants/generated';
import {
  getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions,
  usePutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
} from 'lib/datasource-configuration/datasource-configuration';
import {
  DatasourceConfigurationArea,
  DatasourceConfigurationCompetition,
  ProjectConfigurationCreateSchema,
  ProjectGetSchema
} from 'lib/model';
import { getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions } from 'lib/project-configuration/project-configuration';
import Button from 'modules/common/Button';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  getDataSourceAuthType,
  getMinimumStartSeasonFromDataSourceConfig,
  getSelectedAreasFromDataSourceConfig,
  getSelectedLeaguesFromDataSourceConfig
} from 'utils/helpers';
import { ConfigurationFormValues, DataSourceDialogProps } from 'utils/interfaces';
import ChevronRight from 'assets/chevron-right.svg?react';
import DataSourceAuthentication from 'modules/dataSources/dataSourcesActivation/components/authentication/DataSourceAuthentication';
import useActiveProject from 'contexts/project/projectContext';
import DataSourceConfigurationForm from 'modules/dataSources/components/DataSourceConfigurationForm';

const EditDataSourceDialog = memo(function EditDataSourceDialog({
  open,
  setOpen,
  dataSource,
  dataSourceConfiguration
}: DataSourceDialogProps) {
  const [showCredentials, setShowCredentials] = useState(false);
  const authType = getDataSourceAuthType(dataSource);

  const { control, handleSubmit } = useForm<ConfigurationFormValues>({
    defaultValues: {
      areas:
        getSelectedAreasFromDataSourceConfig(dataSourceConfiguration, dataSource) ??
        DefaultConfigurationFormValues.areas,
      leagues:
        getSelectedLeaguesFromDataSourceConfig(dataSourceConfiguration, dataSource) ??
        DefaultConfigurationFormValues.leagues,
      min_season:
        getMinimumStartSeasonFromDataSourceConfig(dataSourceConfiguration) ?? DefaultConfigurationFormValues.min_season,
      refreshFrequency:
        dataSourceRefreshFrequencyOptions.find(
          (x) => x.id === dataSourceConfiguration.frequency!.substring(4).trimStart()
        ) ?? DefaultConfigurationFormValues.refreshFrequency,
      time:
        CRONTimeOptions.find((x) => x.id === dataSourceConfiguration?.frequency!.substring(0, 4).trimEnd()) ??
        DefaultConfigurationFormValues.time
    }
  });
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const { mutate: updateDataSource } =
    usePutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId();

  const [loading, setLoading] = useState(false);

  function editConfiguration(data: ConfigurationFormValues) {
    setLoading(true);
    const { stats: _, ...rest } = dataSourceConfiguration;
    updateDataSource(
      {
        projectConfigurationId: project.project_configuration_latest!,
        datasourceConfigurationId: dataSourceConfiguration.id!,
        data: {
          ...rest,
          datasource_configuration_areas: data.areas.map(
            (x) =>
              ({
                datasource_area_id: x.id,
                // all areas have the same start season
                start_season: data.min_season?.id ?? undefined
              }) as DatasourceConfigurationArea
          ),
          datasource_configuration_competitions: data.leagues.map(
            (x) =>
              ({
                datasource_competition_id: x.id,
                // all competitions have the same start season
                start_season: data.min_season?.id ?? undefined
              }) as DatasourceConfigurationCompetition
          ),
          datasource_configuration_options: dataSourceConfiguration.datasource_configuration_options!.map((x) => ({
            datasource_option_id: x.datasource_option_id,
            value: x.value
          })),
          datasource_configuration_endpoints: dataSourceConfiguration.datasource_configuration_endpoints!.map((x) => ({
            datasource_endpoint_id: x.datasource_endpoint_id
          })),
          frequency: data.time.id + ' ' + data.refreshFrequency.id
        }
      },
      {
        onSuccess: async (result: ProjectConfigurationCreateSchema) => {
          queryClient.removeQueries({
            queryKey: ['project', project.id, 'configuration', project.project_configuration_latest]
          });

          await queryClient.invalidateQueries({ queryKey: ['project', project.id], exact: true, refetchType: 'all' });

          // Fetch data source configurations
          await queryClient.prefetchQuery(
            getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions(result.id!, {
              query: {
                queryKey: ['project-configuration', result.id, 'datasource-configuration'],
                staleTime: Infinity
              }
            })
          );

          // Start project configuration refetch in the background
          queryClient.prefetchQuery(
            getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions(
              result.project!,
              result.id!,
              { nested: true },
              {
                query: {
                  queryKey: ['project', result.project, 'configuration', result.id],
                  staleTime: Infinity
                }
              }
            )
          );

          setLoading(false);
          setOpen(false);
          toast.success('Data source configuration saved successfully.');
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const data = err.response?.data;
            let errorMessage: string | JSX.Element = '';
            if (data.error) {
              errorMessage = data.error;
            }
            if (data._schema) {
              const errors = data._schema as string[];
              errorMessage = (
                <div>
                  {errors.map((error, index) => (
                    <div key={index}>{error}</div>
                  ))}
                </div>
              );
            }
            toast.error(errorMessage);
          }
          setLoading(false);
        }
      }
    );
  }

  function handleCancel() {
    setOpen(false);
  }

  function authenticate() {
    setShowCredentials(false);
    queryClient.invalidateQueries({ queryKey: ['dataSourcesById', dataSource.id] });
  }

  return (
    <DialogBase title="Edit Data Source" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        {showCredentials ? (
          <>
            <div className="flex items-center bg-gray-50 p-3">
              <DataSourceTitle name={dataSource.name!} image={dataSource?.logo_image_path} />
              <button
                className="flex items-center"
                onClick={() => {
                  setShowCredentials(false);
                }}
              >
                <span className="text-xs font-semibold text-brand-800">Edit datasource</span>
                <ChevronRight width={24} height={24} className="fill-brand-800" />
              </button>
            </div>
            <DataSourceAuthentication
              dataSourceId={dataSource.id!}
              projectId={project!.id!}
              dataSource={dataSource.name!}
              website={dataSource.website!}
              accountDataType={authType}
              authenticated={false}
              configured={true}
              setAuthenticated={authenticate}
              note={
                <>
                  In order to update connection to this Data Source you need to <strong>enter new credentials</strong>.
                </>
              }
            />
          </>
        ) : (
          <>
            <div className="flex items-center bg-gray-50 p-3">
              <DataSourceTitle name={dataSource.name!} image={dataSource?.logo_image_path} />
              <button
                className="flex items-center"
                onClick={() => {
                  setShowCredentials(true);
                }}
              >
                <span className="text-xs font-semibold text-brand-800">Update credentials</span>
                <ChevronRight width={24} height={24} className="fill-brand-800" />
              </button>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-md font-semibold">Configure Data Source</span>
              <span className="text-sm">
                Updating your Data Source preferences may require some time to synchronize.
              </span>
            </div>
            <DataSourceConfigurationForm
              control={control}
              dataSource={dataSource}
              handleSubmit={handleSubmit(editConfiguration)}
            />
          </>
        )}
      </DialogContent>
      {!showCredentials && (
        <DialogFooter>
          <Button size="lg" variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button isSubmitButton={true} size="lg" form="data-source-configuration-form" loading={loading}>
            Save Updates
          </Button>
        </DialogFooter>
      )}
    </DialogBase>
  );
});

export default EditDataSourceDialog;
