import { useQueryClient } from '@tanstack/react-query';
import User from 'assets/user.svg?react';
import { useGetProjectProjectIdDashboardUsersInRolesCount } from 'lib/dashboard/dashboard';
import { ProjectSchema } from 'lib/model';
import Button from 'modules/common/Button';
import { Fragment, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { snakeCaseToWords } from 'utils/helpers';
import DashboardProgressBar from './DashboardProgressBar';
import DashboardSubscriptionUsersSkeleton from './DashboardSubscriptionUsersSkeleton';
import useActiveProject from 'contexts/project/projectContext';

function DashboardSubscriptionUsersCard() {
  const { project } = useActiveProject();
  const { data: dashboardUsersCount, isPending: isDashboardUsersCountPending } =
    useGetProjectProjectIdDashboardUsersInRolesCount(project.id!);

  const navigate = useNavigate();

  function goToTierPlans() {
    navigate('/settings/tier-plans');
  }

  return (
    <div className="flex grow flex-col gap-6 rounded-xl bg-white p-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-2">
          <User className="size-5 fill-gray-500" />
          <span className="text-xs font-semibold text-gray-500">USERS</span>
        </div>
        <Button variant="secondary" size="tiny" isFullWidth={false} onClick={goToTierPlans}>
          Add Seats
        </Button>
      </div>
      <div className="grid grid-cols-enhance-middle items-center gap-3">
        {isDashboardUsersCountPending ? (
          <DashboardSubscriptionUsersSkeleton />
        ) : (
          dashboardUsersCount?.objects?.map((user) => (
            <Fragment key={user.role}>
              <span className="text-xs font-medium">{snakeCaseToWords(user.role)}</span>
              <DashboardProgressBar progress={user.count / user.max} />
              <div className="flex justify-end gap-px">
                <span className="text-xs font-semibold">{user.count}</span>
                <span className="text-xs font-semibold text-gray-400">/</span>
                <span className="text-xs font-semibold text-gray-400">{user.max}</span>
              </div>
            </Fragment>
          ))
        )}
      </div>
    </div>
  );
}

export default memo(DashboardSubscriptionUsersCard);
