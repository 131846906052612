import { useQueryClient } from '@tanstack/react-query';
import Info from 'assets/info.svg?react';
import useAuth from 'contexts/auth/authContext';
import { ProjectSchema, ReportNestedSchema, UserAccountGetSchema } from 'lib/model';
import { useGetReportElementTemplates, useGetReportReportId } from 'lib/report/report';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { canUserEditReport } from '../helpers';
import AddElementButton from './components/AddElementButton';
import ReportHeader from './components/ReportHeader';
import ElementCard from './components/elements/Card/ElementCard';
import ReportCard from './components/reportCards/ReportCard';

function canUserAccessReport(report: ReportNestedSchema, user: UserAccountGetSchema): boolean {
  const sharedUser = report.users?.find((x) => x.user_id === user.id);
  const ownerUser = report.owner_user === user.id;
  return ownerUser || sharedUser !== undefined;
}

const ReportPage = memo(function ReportPage() {
  const { reportId } = useParams();
  const { user, isOwner, isAdmin } = useAuth();

  const {
    data: report,
    isFetching: isReportFetching,
    isPending: isReportPending
  } = useGetReportReportId(reportId!, {
    query: {
      queryKey: ['reports', reportId!],
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false
    }
  });

  const { data: elementTemplates, isPending: isPendingElementTemplates } = useGetReportElementTemplates(
    {
      report_id: reportId!
    },
    {
      query: {
        queryKey: ['reportElementTemplates', report?.report_type, report?.report_entities],
        enabled: !!report,
        staleTime: Infinity
      }
    }
  );

  if (!isReportFetching && !isOwner && !isAdmin && !canUserAccessReport(report!, user)) {
    throw new Error('403 Forbidden');
  }

  const canEdit =
    !isReportPending && (canUserEditReport(report!, user) || isOwner || isAdmin || report?.owner_user === user.id);

  return (
    <div className="relative flex w-full flex-col">
      {!isReportFetching && <ReportHeader report={report} />}
      <div className={twJoin('relative flex gap-6 max-lg:flex-col', report?.report_type === 'scout' && 'flex-col')}>
        <div
          className={twJoin(
            'flex h-fit flex-col gap-6 lg:top-[116px] lg:min-w-[400px]',
            report?.report_type !== 'scout' && 'lg:sticky'
          )}
        >
          <ReportCard report={report} />
        </div>
        <div className="flex w-full flex-col gap-6">
          {report && report.report_elements!.length === 0 && (
            <div className="flex w-full items-center gap-6 rounded-xl bg-white p-10">
              <Info width={64} height={64} className="shrink-0 fill-gray-300" />
              <p className="text-md font-medium">
                Craft your personalized report layout. Add sections and your visual preferences below.
              </p>
            </div>
          )}
          {report?.report_elements?.map((elementId, i) => (
            <ElementCard
              key={elementId}
              report={report}
              elementId={elementId}
              elementTemplates={elementTemplates}
              first={i === 0}
              last={i === report.report_elements!.length! - 1}
            />
          ))}
          {canEdit && (
            <>
              {elementTemplates?.objects?.length === 0 ? (
                <button
                  className="flex w-full items-center justify-center rounded-xl border border-dashed border-gray-400 bg-gray-200 px-2.5 py-6 disabled:cursor-not-allowed"
                  disabled={true}
                >
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-medium text-gray-500">
                      No available match data for selected players or teams
                    </span>
                  </div>
                </button>
              ) : (
                <AddElementButton
                  elementTemplates={elementTemplates}
                  isPendingElementTemplates={isPendingElementTemplates}
                  report={report!}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default ReportPage;
