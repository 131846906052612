import CheckCircle from 'assets/check-circle.svg?react';
import Progress from 'assets/progress.svg?react';
import Unknown from 'assets/unknown.svg?react';
import Unlink from 'assets/unlink.svg?react';
import Warning from 'assets/warning.svg?react';
import SyncStatusEnum from 'constants/enums/syncStatus';
import { memo, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

interface SyncStatusBadgeProps {
  status: string;
  inactive: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const DataSourceSyncStatusBadge = memo(function SyncStatusBadge({
  status,
  inactive,
  size = 'md'
}: SyncStatusBadgeProps) {
  const content = useMemo(() => {
    let textSize = 'text-sm';
    let iconSize = 14;

    switch (size) {
      case 'sm':
        textSize = 'text-micro';
        iconSize = 12;
        break;
      case 'md':
        textSize = 'text-tiny';
        iconSize = 14;
        break;
      case 'lg':
        textSize = 'text-sm';
        iconSize = 16;
        break;
    }

    if (inactive) {
      return (
        <>
          <Unlink width={iconSize} height={iconSize} className="fill-gray-500" />
          <span className={`${textSize} font-semibold text-gray-500`}>INACTIVE</span>
        </>
      );
    }
    switch (status) {
      case SyncStatusEnum.FAILED:
        return (
          <>
            <Warning width={iconSize} height={iconSize} className="fill-red-600" />
            <span className={`${textSize} font-semibold text-red-600`}>SYNC FAILED</span>
          </>
        );
      case SyncStatusEnum.SUCCESSFUL:
        return (
          <>
            <CheckCircle width={iconSize} height={iconSize} className="fill-green-600" />
            <span className={`${textSize} font-semibold text-green-600`}>SYNCED</span>
          </>
        );
      case SyncStatusEnum.IN_PROGRESS:
        return (
          <>
            <Progress width={iconSize} height={iconSize} className="fill-orange-600" />
            <span className={`${textSize} font-semibold text-orange-600`}>SYNC IN PROGRESS</span>
          </>
        );
      case SyncStatusEnum.DEPLOYING:
        return (
          <>
            <Progress width={iconSize} height={iconSize} className="fill-blue-600" />
            <span className={`${textSize} font-semibold text-blue-600`}>DEPLOYING</span>
          </>
        );
      default:
        return (
          <>
            <Unknown width={iconSize} height={iconSize} className="fill-gray-500" />
            <span className={`${textSize} font-semibold text-gray-500`}>UNKNOWN</span>
          </>
        );
    }
  }, [status, inactive]);

  return (
    <div
      className={twMerge(
        'flex w-fit items-center gap-1 rounded-badge border',
        size === 'sm' && 'px-1.5 py-1',
        size === 'md' && 'px-3 py-2',
        size === 'lg' && 'px-3 py-2',
        status === SyncStatusEnum.FAILED && 'border-red-200 bg-red-50',
        status === SyncStatusEnum.SUCCESSFUL && 'border-green-400 bg-green-50',
        status === SyncStatusEnum.IN_PROGRESS && 'border-orange-400 bg-orange-50',
        status === SyncStatusEnum.DEPLOYING && 'border-blue-400 bg-blue-50',
        status === SyncStatusEnum.UNKNOWN && 'border-gray-300 bg-gray-50',
        inactive && 'border-gray-300 bg-gray-50'
      )}
    >
      {content}
    </div>
  );
});

export default DataSourceSyncStatusBadge;
