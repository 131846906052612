import { ProjectSchema } from 'lib/model';
import { useProjectSubscriptionExpiration } from '../../hooks/useGetProjectSubscription';
import { twJoin } from 'tailwind-merge';

interface SubscriptionCellProps {
  project: ProjectSchema;
}

const SubscriptionCell = ({ project }: SubscriptionCellProps) => {
  const { expirationDate, expirationString } = useProjectSubscriptionExpiration(project.id!);
  const currentDate = new Date();
  const expired = expirationDate && currentDate > expirationDate;
  return <div className={twJoin('text-xs', expired && 'text-red-600')}>{expirationString}</div>;
};

export default SubscriptionCell;
