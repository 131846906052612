import { useGetProjectProjectIdUser } from 'lib/project-user/project-user';
import { useMemo } from 'react';
import { isActiveUser } from 'utils/helpers';

export function useProjectUsersStatus(projectId: string) {
  const { isPending: isProjectUsersPending, data: projectUsers } = useGetProjectProjectIdUser(projectId!, {
    query: {
      queryKey: ['project', projectId, 'users'],
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false
    }
  });

  const activeUsers = useMemo(() => {
    if (!projectUsers?.objects) {
      return [];
    }
    return projectUsers.objects.filter(isActiveUser) ?? [];
  }, [projectUsers]);

  const invitedUsers = useMemo(() => {
    if (!projectUsers?.objects) {
      return [];
    }
    return projectUsers.objects.filter((user) => !isActiveUser(user)) ?? [];
  }, [projectUsers]);

  const allUsers = useMemo(() => {
    return projectUsers?.objects ?? [];
  }, [projectUsers]);

  return { isProjectUsersPending, activeUsers, invitedUsers, projectUsers: allUsers };
}
