import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import Close from 'assets/close.svg?react';
import Hamburger from 'assets/hamburger.svg?react';
import Logo from 'assets/logo.svg?react';
import Notification from 'assets/notification.svg?react';
import useAppNavigation from 'hooks/useAppNavigation';
import Divider from 'modules/common/Divider';
import { memo } from 'react';
import NavLinks from './NavLinks';
import UserDropdown from './UserDropdown';
import ProjectSelectInput from 'modules/common/Form/Select/ProjectSelectInput';
import CircleImage from 'modules/common/CircleImage';
import useActiveProject from 'contexts/project/projectContext';
import { useGetProject } from 'lib/project/project';

const MobileNavigation = memo(function MobileNavigation() {
  const { user, primaryRoutes, secondaryRoutes } = useAppNavigation();
  const { project, changeActiveProject } = useActiveProject();
  const { data: projects } = useGetProject(undefined, {
    query: { queryKey: ['projects'], enabled: !!user.is_admin, staleTime: Infinity }
  });

  return (
    <Popover className="sticky top-0 z-30 flex h-14 w-full items-center justify-between bg-gray-950 px-4">
      {({ open, close }) => (
        <>
          <Logo className="fill-white" width={86} height={32} />
          <PopoverButton>
            {!open ? (
              <Hamburger className="fill-white" width={32} height={32} />
            ) : (
              <Close className="fill-white" width={32} height={32} />
            )}
          </PopoverButton>
          <PopoverPanel className="absolute left-0 top-14 z-50 h-[calc(100vh_-56px)] w-full bg-white">
            <div className="flex h-full grow flex-col gap-6 rounded-b-2xl bg-white px-4 py-6">
              {user.is_admin ? (
                <>
                  <ProjectSelectInput
                    options={projects?.objects ?? []}
                    label="Select Project"
                    defaultProject={project}
                    handleChange={(project) => {
                      if (project) {
                        changeActiveProject(project.id!);
                      }
                    }}
                  />
                </>
              ) : (
                <div className="flex w-full items-center justify-center gap-3 p-2">
                  <CircleImage
                    image={project?.organization_image_path}
                    text={project!.organization_name!.charAt(0)}
                    size="size-8"
                  />
                  <span className="pointer-events-none grow truncate text-md font-medium">
                    {project?.organization_name}
                  </span>
                </div>
              )}

              <div className="px-2">
                <Divider />
              </div>
              <nav className="flex grow flex-col items-center justify-between">
                <ul className="flex w-full flex-col items-center">
                  <NavLinks items={primaryRoutes} expanded={true} mobileCloseNavigation={close} />
                </ul>
                <ul className="flex w-full flex-col items-center">
                  <NavLinks items={secondaryRoutes} expanded={true} mobileCloseNavigation={close} />
                </ul>
              </nav>
              <Divider />
              <div className="flex items-center justify-between gap-2">
                <UserDropdown expanded={true} user={user!} />
                <div className="flex items-center justify-center rounded-md p-3.5 hover:bg-gray-50">
                  <Notification width={20} height={20} className="fill-gray-400" />
                </div>
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
});

export default MobileNavigation;
