import DataSourcesFiltersProvider from 'contexts/dataSourcesFilters/DataSourcesFiltersProvider';
import { FilteredDataSourcesProvider } from 'contexts/filteredDataSources/FilteredDataSourcesProvider';
import { SelectedDataSource, SelectedDataSourcesContext } from 'contexts/selectedDataSourcesContext';
import { useGetProjectProjectIdDataTable } from 'lib/data-table/data-table';
import { memo, useState } from 'react';
import { Outlet } from 'react-router';
import DataSourcesHeader from './components/DataSourcesHeader';
import useActiveProject from 'contexts/project/projectContext';

const DataSourcesLayout = memo(function DataSourcesLayout() {
  const { project } = useActiveProject();
  const [selectedDataSources, setSelectedDataSources] = useState<SelectedDataSource[]>([]);

  useGetProjectProjectIdDataTable(project.id!, {
    query: {
      queryKey: ['project', project.id, 'data-tables'],
      staleTime: Infinity
    }
  });

  return (
    <DataSourcesFiltersProvider>
      <FilteredDataSourcesProvider>
        <div className="flex w-full flex-col items-center">
          <DataSourcesHeader />
          <Outlet context={{ selectedDataSources, setSelectedDataSources } satisfies SelectedDataSourcesContext} />
        </div>
      </FilteredDataSourcesProvider>
    </DataSourcesFiltersProvider>
  );
});

export default DataSourcesLayout;
