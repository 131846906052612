import { useQueryClient } from '@tanstack/react-query';
import { ProjectRoleSchemaName } from 'lib/model';
import { useGetUser, usePostUserLogout } from 'lib/user-account/user-account';
import { PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './authContext';

export function AuthProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: user } = useGetUser({
    query: { queryKey: ['user'], staleTime: Infinity, refetchInterval: 1000 * 60 * 30 }
  });

  const { mutate: logOut } = usePostUserLogout();

  const value = useMemo(() => {
    let isViewer = false;
    let isOwner = false;
    let isAdmin = user?.is_admin ? true : false;
    if (user?.user_projects && user.user_projects.length > 0) {
      isViewer =
        user?.user_projects![0].project_role_name === ProjectRoleSchemaName.viewer ||
        user?.user_projects![0].project_role_name === ProjectRoleSchemaName.scout_viewer;

      isOwner = user?.user_projects![0].project_role_name === ProjectRoleSchemaName.owner;
      isAdmin = user?.user_projects![0].project_role_name === ProjectRoleSchemaName.admin || !!user.is_admin;
    }

    function logout() {
      if (localStorage.getItem('inviteUsersState') === 'skipped') {
        localStorage.removeItem('inviteUsersState');
      }
      logOut();
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('active_project');
      queryClient.clear();
      navigate('/login');
    }

    return {
      logout,
      user,
      isViewer,
      isOwner,
      isAdmin
    } as AuthContext;
  }, [navigate, queryClient, user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
