import { useQueryClient } from '@tanstack/react-query';
import SyncStatusEnum from 'constants/enums/syncStatus';
import useAuth from 'contexts/auth/authContext';
import { usePatchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive } from 'lib/datasource-configuration/datasource-configuration';
import { useGetDatasourceDatasourceId } from 'lib/datasource/datasource';
import { DatasourceConfigurationNestedSchema, ProjectConfigurationSchema, ProjectGetSchema } from 'lib/model';
import Button from 'modules/common/Button';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import DateTime from 'modules/common/DateTime';
import Divider from 'modules/common/Divider';
import DataSourceSyncStatusBadge from 'modules/dataSources/components/DataSourceSyncStatusBadge';
import DataSourcesCardBase from 'modules/dataSources/components/DataSourcesCardBase';
import { memo, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { toShortNumber } from 'utils/helpers';
import DataSourceConfigurationDropdown from './DataSourceConfigurationDropdown/DataSourceConfigurationDropdown';
import useActiveProject from 'contexts/project/projectContext';

interface ConnectedDataSourceCardProps {
  dataSourceConfiguration: DatasourceConfigurationNestedSchema;
  border?: boolean;
}

const ConnectedDataSourceCard = memo(function ConnectedDataSourceCard({
  dataSourceConfiguration,
  border = false
}: ConnectedDataSourceCardProps) {
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const [loading, setLoading] = useState(false);
  const { isOwner, isAdmin } = useAuth();

  const { data: dataSource, isPending: isDataSourcePending } = useGetDatasourceDatasourceId(
    dataSourceConfiguration.datasource!,
    {
      query: {
        queryKey: ['dataSourcesById', dataSourceConfiguration.datasource!],
        enabled: !!dataSourceConfiguration.datasource,
        staleTime: Infinity
      }
    }
  );
  const { mutate: activate, isPending: isActivatePending } =
    usePatchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive();

  const handleReactivateDataSource = useCallback(
    function activateDataSource() {
      setLoading(true);
      activate(
        {
          projectConfigurationId: project.project_configuration_latest!,
          datasourceConfigurationId: dataSourceConfiguration.id!,
          data: {
            is_active: true
          }
        },
        {
          onSuccess: () => {
            queryClient
              .invalidateQueries({
                queryKey: [
                  'project',
                  project.id,
                  'configuration',
                  project.project_configuration_latest,
                  'datasource-configuration'
                ]
              })
              .then(() => {
                setLoading(false);
              });
          }
        }
      );
    },
    [activate, dataSourceConfiguration.id, project.project_configuration_latest, queryClient]
  );

  if (isDataSourcePending || !dataSource) {
    return null;
  }

  const syncStatus = dataSourceConfiguration.datasource_configuration_status!.status!;
  const stats = Object.entries(dataSourceConfiguration.stats!);
  const syncDate = dataSourceConfiguration.datasource_configuration_status?.stopped_at;

  return (
    <DataSourcesCardBase disabled={!dataSourceConfiguration.is_active} border={border}>
      <div className="flex w-full items-center justify-between">
        <DataSourceTitle name={dataSource.name!} size="md" image={dataSource?.logo_image_path} />
        <div className="flex items-center gap-3">
          <DataSourceSyncStatusBadge status={syncStatus} inactive={!dataSourceConfiguration.is_active} />
        </div>
      </div>
      <Divider />
      <div className="flex w-full flex-wrap gap-2">
        {stats.map(([statName, value]) => (
          <div
            key={statName}
            className={twMerge(
              'flex w-[76px] grow flex-col rounded-md bg-gray-50 p-3',
              syncStatus === SyncStatusEnum.INACTIVE && 'bg-gray-100'
            )}
          >
            <span className="text-tiny font-medium uppercase text-gray-500">{shortenStatName(statName)}</span>
            <span className="text-sm font-bold text-gray-900">{toShortNumber(value)}</span>
          </div>
        ))}
      </div>
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col gap-0.5">
          <span className="text-micro font-medium">LAST SYNCED:</span>
          {syncDate ? <DateTime date={syncDate ? new Date(syncDate) : new Date()} /> : 'N/A'}
        </div>
        {(isOwner || isAdmin) && (
          <div className="flex items-center gap-3">
            {!dataSourceConfiguration.is_active && (
              <Button
                size="md"
                variant="primary"
                onClick={handleReactivateDataSource}
                disabled={isActivatePending}
                loading={loading}
              >
                Reactivate
              </Button>
            )}
            {syncStatus !== SyncStatusEnum.IN_PROGRESS && (
              <DataSourceConfigurationDropdown
                dataSource={dataSource}
                dataSourceConfiguration={dataSourceConfiguration}
              />
            )}
          </div>
        )}
      </div>
    </DataSourcesCardBase>
  );
});

function shortenStatName(name: string) {
  if (name.length > 7) {
    return name.slice(0, 6) + '.';
  } else return name;
}

export default ConnectedDataSourceCard;
