import Database from 'assets/database.svg?react';
import { useGetDatasource } from 'lib/datasource/datasource';
import { ProjectConfigurationNestedSchema, ProjectDeploymentSchema, ProjectSchema, ReportSchema } from 'lib/model';
import { useGetProjectProjectIdConfigurationProjectConfigurationId } from 'lib/project-configuration/project-configuration';
import { useGetProjectProjectId } from 'lib/project/project';

import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import Report from 'assets/report.svg?react';
import User from 'assets/user.svg?react';
import { useGetProjectProjectIdRole } from 'lib/project-role/project-role';
import CircleImage from 'modules/common/CircleImage';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import Divider from 'modules/common/Divider';
import GenericTable from 'modules/common/GenericTable';
import PaginationWithState from 'modules/common/PaginationWithState';
import DataSourceSyncStatusBadge from 'modules/dataSources/components/DataSourceSyncStatusBadge';
import { useMemo, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { parseAWSCronToDate } from 'utils/helpers';
import { UserRow } from 'utils/interfaces';
import { projectUserToUserRow } from 'utils/mappings';
import DashboardDateTime from '../../DataSources/ActiveDataSources/DashboardDateTime';
import { useProjectSubscriptionExpiration } from '../hooks/useGetProjectSubscription';
import { useProjectUsersStatus } from '../hooks/useProjectUsersStatus';
import {
  getProjectProjectIdDeploymentLatest,
  useGetProjectProjectIdDeploymentLatest
} from 'lib/project-deployment/project-deployment';
import StatusEnum from 'constants/enums/status';
import { getDeploymentStatus } from '../util';
import StatusIcon from './StatusIcon';

interface DashboardProjectCardProps {
  project: ProjectSchema;
  reports: ReportSchema[];
}

const DashboardProjectCard = ({ project, reports }: DashboardProjectCardProps) => {
  // General data
  const { data: dataSources } = useGetDatasource(undefined, {
    query: {
      queryKey: ['dataSources'],
      staleTime: Infinity
    }
  });

  const { data: projectData } = useGetProjectProjectId(project.id!, {
    query: {
      queryKey: ['project', project.id],
      enabled: !!project.id,
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  });

  // Project subscription
  const { expirationString } = useProjectSubscriptionExpiration(project.id!);

  // Project users
  const { activeUsers, invitedUsers, projectUsers } = useProjectUsersStatus(project.id!);
  const { data: projectRoles } = useGetProjectProjectIdRole(project.id!, {
    query: { queryKey: ['project', project.id!, 'roles'], staleTime: Infinity }
  });

  // Project aws data

  // Project deployment data
  const { data: deployment } = useGetProjectProjectIdDeploymentLatest(project.id!, {
    query: {
      queryKey: ['project', project.id, 'deployment', 'latest'],
      staleTime: Infinity,
      queryFn: ({ signal }) => {
        return getProjectProjectIdDeploymentLatest(project.id!, {}, signal).catch((err) => {
          if (err.status === 404) {
            return {
              exit_status: StatusEnum.UNKNOWN
            } as ProjectDeploymentSchema;
          } else {
            throw err;
          }
        });
      }
    }
  });

  const deploymentStatus = useMemo(() => {
    if (!deployment) {
      return StatusEnum.UNKNOWN;
    }
    return getDeploymentStatus(deployment);
  }, [deployment]);
  // Project configuration
  const projectConfiguration =
    useGetProjectProjectIdConfigurationProjectConfigurationId<ProjectConfigurationNestedSchema>(
      project.id!,
      projectData!.project_configuration_latest!,
      {},
      {
        query: {
          queryKey: ['project', project.id, 'configuration', projectData?.project_configuration_latest],
          staleTime: Infinity,
          enabled: !!project.id && !!projectData?.project_configuration_latest,
          refetchOnWindowFocus: false
        }
      }
    );

  const connectedDatasources = useMemo(() => {
    if (!dataSources?.objects || !projectConfiguration?.data?.datasource_configurations) {
      return [];
    }

    const projectDatasources = projectConfiguration.data.datasource_configurations;

    const connectedDatasources = dataSources.objects
      .filter((dataSource) =>
        projectDatasources.some((projectDataSource) => projectDataSource.datasource === dataSource.id)
      )
      .map((dataSource) => {
        const configuration = projectDatasources.find(
          (projectDataSource) => projectDataSource.datasource === dataSource.id
        )!;

        const syncDate = configuration.datasource_configuration_status?.stopped_at;
        return { ...dataSource, configuration: configuration, syncDate: syncDate };
      });

    return connectedDatasources ?? [];
  }, [dataSources, projectConfiguration]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5
  });

  const userRows = useMemo(() => {
    return projectUsers.map((user) => projectUserToUserRow(user, projectRoles?.objects ?? []));
  }, [projectUsers]);

  const columnHelper = createColumnHelper<UserRow>();
  const columns = useMemo(() => {
    const columns: ColumnDef<UserRow>[] = [
      columnHelper.accessor('name', {
        id: 'name',
        cell: (info) => (
          <div className="mx-3 ml-6 flex items-center gap-2">
            <CircleImage image={info.row.original.image} text={info.row.original.initials!} size="size-5" />
            <span className={twMerge('text-wrap text-xs font-medium', !info.row.original.id && 'text-gray-500')}>
              {info.renderValue()}
            </span>
          </div>
        ),
        header: () => (
          <span className="mx-3 ml-6 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">USER</span>
        )
      }),
      columnHelper.accessor('email', {
        id: 'email',
        cell: (info) => (
          <span className={twMerge('mx-3 ml-6 text-xs font-medium', !info.row.original.id && 'text-gray-500')}>
            {info.renderValue()}
          </span>
        ),
        header: () => (
          <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">EMAIL</span>
        )
      }),
      columnHelper.accessor('invite_sent', {
        id: 'invite_sent',
        cell: (info) => (
          <span className={twMerge('mx-3 ml-6 text-xs font-medium', !info.row.original.id && 'text-gray-500')}>
            {info.renderValue()}
          </span>
        ),
        header: () => (
          <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">INVITE SENT</span>
        )
      }),
      columnHelper.accessor('last_login', {
        id: 'last_login',
        cell: (info) => (
          <span className={twMerge('mx-3 ml-6 text-xs font-medium', !info.row.original.id && 'text-gray-500')}>
            {info.renderValue()}
          </span>
        ),
        header: () => (
          <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">LAST LOGIN</span>
        )
      }),
      columnHelper.accessor('access_level', {
        id: 'access_level',
        cell: (info) => (
          <span
            className={twMerge(
              'mx-3 flex items-center justify-end gap-1 text-xs font-medium',
              !info.row.original.id && 'text-gray-500'
            )}
          >
            {info.row.original.id ? info.renderValue() : 'Invitation sent'}
          </span>
        ),
        header: () => (
          <span className="mx-3 flex justify-end gap-2 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">
            ACCESS LEVEL
          </span>
        ),
        meta: 'text-end '
      })
    ];
    return columns;
  }, [columnHelper]);

  const table = useReactTable({
    data: userRows,
    columns,
    onPaginationChange: setPagination,
    state: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    renderFallbackValue: '-'
  });

  return (
    <div className={twJoin('flex flex-col gap-4 bg-gray-50 p-6 transition-transform duration-200')}>
      <div className="flex items-center gap-3 rounded-md">
        <CircleImage
          image={project.organization_image_path}
          text={project!.organization_name!.charAt(0)}
          size="size-8"
        />
        {project.organization_name}
      </div>
      <Divider />
      {/* General data */}
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-3">
          <Report className="size-5 fill-gray-700" />
          <span className="text-sm font-semibold">General</span>
        </div>
        <div className="flex items-center justify-between gap-6 text-xs">
          <span className="font-semibold">Deployment:</span>
          <div className="flex items-center gap-2">
            {deploymentStatus}
            <StatusIcon status={deploymentStatus} iconSize={16} />
          </div>
        </div>
        <div className="flex items-center justify-between gap-6 text-xs">
          <span className="font-semibold">AWS Cost:</span>
          [PH] 1 credits/day
        </div>
        <div className="flex items-center justify-between text-xs">
          <span className="font-semibold">Subscription expiry:</span>
          {expirationString}
        </div>
        <div className="flex items-center justify-between gap-6 text-xs">
          <span className="font-semibold">Reports:</span>
          {reports.length}
        </div>
      </div>
      {/* Account data */}
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-3">
          <User className="size-5 fill-gray-700" />
          <span className="text-sm font-semibold">Account</span>
        </div>

        <div className="flex justify-between gap-3">
          <div className="flex flex-grow items-center justify-between text-xs">
            <span className="font-semibold">Active users:</span>
            {activeUsers.length}
          </div>
          <Divider vertical />
          <div className="flex flex-grow items-center justify-between text-xs">
            <span className="font-semibold">Invited users:</span>
            {invitedUsers.length}
          </div>
        </div>
        {projectUsers.length > 0 ? (
          <>
            <GenericTable table={table} isDataFetching={false} />
            <PaginationWithState pagination={pagination} setPagination={setPagination} rowCount={userRows.length} />
          </>
        ) : (
          <div className="flex items-center justify-center rounded-xl bg-gray-50 py-4">
            <div className="flex flex-col items-center gap-3">
              <User className="size-16 fill-gray-300" />
              <span className="text-sm font-medium text-gray-500">No users</span>
            </div>
          </div>
        )}
      </div>
      {/* Data sources */}
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-3">
          <Database className="size-5 fill-gray-700" />
          <span className="text-sm font-semibold">Active Data Sources</span>
        </div>
        <div className="flex flex-wrap justify-between gap-6">
          {connectedDatasources.length === 0 ? (
            <span className="w-full text-xs font-semibold">None</span>
          ) : (
            <>
              {connectedDatasources.map((dataSource) => {
                const nextDate = parseAWSCronToDate(dataSource.configuration.frequency ?? '');
                return (
                  <div key={dataSource.id} className="flex flex-grow flex-col gap-3">
                    <div className="flex w-full items-center justify-between gap-6">
                      <DataSourceTitle
                        name={dataSource!.name!}
                        image={dataSource?.logo_image_path}
                        size="sm"
                        imageSize="sm"
                      />
                      <DataSourceSyncStatusBadge
                        status={dataSource.configuration.datasource_configuration_status!.status!}
                        inactive={!dataSource.configuration.is_active}
                        size="sm"
                      />
                    </div>
                    <Divider />
                    <div className="flex flex-col">
                      <div className="flex w-full justify-between gap-6">
                        <span className="text-tiny font-medium">LAST SYNCED:</span>
                        {dataSource.syncDate ? (
                          <DashboardDateTime date={dataSource.syncDate ? new Date(dataSource.syncDate) : new Date()} />
                        ) : (
                          <div className="text-tiny font-semibold">N/A</div>
                        )}
                      </div>
                      <div className="flex w-full justify-between gap-6">
                        <span className="text-tiny font-medium">NEXT SYNC:</span>
                        <span className="text-tiny font-semibold"> [PH]</span>
                        {/* {nextDate ? <DashboardDateTime date={nextDate} /> : 'UNKNOWN'} */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardProjectCard;
