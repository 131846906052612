import useAuth from 'contexts/auth/authContext';
import { ProjectRoleSchemaName } from 'lib/model';
import { memo, PropsWithChildren } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { NavigationRoute } from 'utils/interfaces';

interface NavigationBarProps extends PropsWithChildren {
  routes: NavigationRoute[];
}

const NavigationBar = memo(function NavigationBar({ routes, children }: NavigationBarProps) {
  const { user } = useAuth();
  const userRole = user.user_projects![0]
    ? user.user_projects![0].project_role_name
    : user.is_admin
      ? ProjectRoleSchemaName.admin
      : undefined;
  const { pathname, search } = useLocation();

  return (
    <div className="flex w-full flex-wrap justify-between gap-6 overflow-x-auto rounded-xl bg-white p-4">
      <div className="flex items-center gap-2">
        {routes.map((route) =>
          route.roleAccess.includes(userRole!) || route.roleAccess.length === 0 ? (
            <NavLink
              key={route.name}
              to={route.href}
              className={twJoin(
                'flex items-center gap-2 rounded-md px-4 py-2.5 text-xs font-semibold uppercase',
                route.isActive(`${pathname}${search}`)
                  ? 'bg-brand-50 text-brand-800 *:fill-brand-800'
                  : 'bg-white text-gray-600 *:fill-gray-600'
              )}
            >
              {route.Icon && <route.Icon className="size-4" />}
              <span className="text-xs font-semibold uppercase">{route.name}</span>
            </NavLink>
          ) : (
            <></>
          )
        )}
      </div>
      {children}
    </div>
  );
});

export default NavigationBar;
