import { useQueryClient } from '@tanstack/react-query';
import useAuth from 'contexts/auth/authContext';
import useActiveProject from 'contexts/project/projectContext';
import { ProjectGetSchema } from 'lib/model';
import { useDeleteProjectProjectId } from 'lib/project/project';
import Button from 'modules/common/Button';
import CircleImage from 'modules/common/CircleImage';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo, useState } from 'react';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';

function DeleteOrganizationDialog({ open, setOpen }: DialogProps) {
  const { logout } = useAuth();
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const [deleteInitiated, setDeleteInitiated] = useState(false);

  function initiateDelete() {
    setDeleteInitiated(true);
  }

  function handleCancel() {
    setOpen(false);
    setDeleteInitiated(false);
  }

  const { mutate: deleteOrg } = useDeleteProjectProjectId();

  function deleteOrganization() {
    deleteOrg(
      { projectId: project!.id! },
      {
        onSuccess: () => {
          toast.success('You have successfully deleted your organization. You are about to be logged out.', {
            autoClose: 1000,
            onClose: () => logout()
          });
        }
      }
    );
    return null;
  }

  return (
    <DialogBase title="Delete accounts" open={open} onCancel={handleCancel}>
      <DialogContent>
        {deleteInitiated ? (
          <div className="flex flex-col gap-10">
            <span className="text-md font-semibold">Confirm Organization Deletion</span>
            <div className="flex flex-col gap-6">
              <span className="text-md font-medium">
                We'll keep your data for 30 days in case you change your mind. After this period, your data will be
                permanently deleted and cannot be recovered.
              </span>
              <span className="text-md font-medium">Are you sure you want to delete your organization?</span>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-6">
            <span className="text-md font-medium">
              You are about to delete <b>{project?.organization_name}</b> organization.
            </span>
            <div className="flex items-center gap-3 rounded-md bg-gray-50 px-3 py-2">
              <CircleImage
                image={project?.organization_image_path}
                text={project!.organization_name!.charAt(0)}
                size="size-9"
              />

              <span className="text-sm font-semibold">{project?.organization_name}</span>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        {deleteInitiated ? (
          <Button variant="red" size="lg" onClick={deleteOrganization}>
            Delete organization
          </Button>
        ) : (
          <Button variant="red" size="lg" onClick={initiateDelete}>
            Delete organization
          </Button>
        )}
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(DeleteOrganizationDialog);
