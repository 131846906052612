import { useQueryClient } from '@tanstack/react-query';
import Plus from 'assets/plus.svg?react';
import useActiveProject from 'contexts/project/projectContext';
import { useSelectedDataSources } from 'contexts/selectedDataSourcesContext';
import { DatasourceConfigurationsNestedSchema, DatasourcesSchema } from 'lib/model';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { DialogProps, ValueOption } from 'utils/interfaces';

interface AddDataSourceFromValues {
  dataSource: ValueOption | null;
}

const defaultAddDataSourceFromValues = {
  dataSource: null
};

const AddAnotherDataSourceDialog = memo(function AddAnotherDataSourceDialog({ open, setOpen }: DialogProps) {
  const { selectedDataSources, setSelectedDataSources } = useSelectedDataSources();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid: isFormValid }
  } = useForm<AddDataSourceFromValues>({
    defaultValues: defaultAddDataSourceFromValues
  });
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const dataSources = queryClient.getQueryData<DatasourcesSchema>(['dataSources'])!;
  const dataSourceConfigs = queryClient.getQueryData<DatasourceConfigurationsNestedSchema>([
    'project-configuration',
    project.project_configuration_latest,
    'datasource-configuration'
  ]);
  const dataSourceOptions: ValueOption[] =
    dataSources?.objects
      ?.filter(
        (ds) =>
          !selectedDataSources.some((sds) => sds.dataSource.id === ds.id) &&
          !dataSourceConfigs?.objects?.some((x) => x.datasource === ds.id)
      )
      .map((ds) => ({
        label: ds.name!,
        id: ds.id!
      })) ?? [];

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const addDataSource = useCallback(
    function addDataSource(data: AddDataSourceFromValues) {
      if (data.dataSource === null) {
        return;
      }

      setSelectedDataSources((prev) => {
        const canConfigure = prev.every((sds) => sds.state.configured);

        return [
          ...prev,
          {
            dataSource: dataSources!.objects!.find((ds) => ds.id === data.dataSource!.id)!,
            state: {
              authenticated: false,
              configured: false,
              expanded: canConfigure
            }
          }
        ];
      });
      reset();

      setOpen(false);
    },
    [dataSources, reset, setOpen, setSelectedDataSources]
  );

  return (
    <DialogBase title="Choose Data Source" open={open} onCancel={handleCancel}>
      <DialogContent>
        <form className="flex w-full gap-6" id="add-data-source-form" onSubmit={handleSubmit(addDataSource)}>
          <SelectInput
            formProps={{
              control: control,
              rules: { required: { value: true, message: 'You must select a data source.' } },
              name: 'dataSource'
            }}
            searchable={true}
            label="Choose a Data Source"
            options={dataSourceOptions}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" isFullWidth={true} onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton={true} form="add-data-source-form" disabled={!isFormValid}>
          <Plus width={20} height={20} />
          <span>Add Data Source</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default AddAnotherDataSourceDialog;
