import { dataSourceRefreshFrequencyOptions } from 'constants/formOptions';
import { CRONTimeOptions, seasonOptions } from 'constants/generated';
import useIsMobile from 'hooks/useIsMobile';
import { useObjectsOptions } from 'hooks/useOptions';
import { DatasourceSchema } from 'lib/model';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { FormEventHandler, memo } from 'react';
import { Control, useFormState } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import { ConfigurationFormValues, ValueOption } from 'utils/interfaces';

interface DatasourceConfigurationFormProps {
  dataSource: DatasourceSchema;
  control: Control<ConfigurationFormValues>;
  handleSubmit: FormEventHandler<HTMLFormElement>;
}

const DatasourceConfigurationForm = ({ dataSource, control, handleSubmit }: DatasourceConfigurationFormProps) => {
  const isMobile = useIsMobile();
  const leagueOptions = useObjectsOptions({ objects: dataSource.datasource_competitions });
  const areaOptions = useObjectsOptions({ objects: dataSource.datasource_areas });
  const showSeasons =
    (dataSource.datasource_areas && dataSource.datasource_areas.length > 0) ||
    (dataSource.datasource_competitions && dataSource.datasource_competitions.length > 0);

  const formState = useFormState({ control });

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6" id="data-source-configuration-form">
      {dataSource.datasource_areas && dataSource.datasource_areas.length > 0 && (
        <div>
          <SelectInput
            formProps={{
              control: control,
              name: 'areas',
              rules: {
                validate: {
                  minLength: (values: ValueOption[]) => values.length > 0 || `Please select at least one area.`,
                  maxLength: (values: ValueOption[]) =>
                    values.length <= 5 || `Currently it's possible to select at most 5 areas.`
                }
              }
            }}
            multiple
            label="Areas"
            placeholder="Select areas"
            searchable={true}
            options={areaOptions}
            error={formState.errors.areas}
          />
          <div className="mt-3 text-sm italic text-gray-400">
            Note: Your account may not have access to all of the listed areas.
          </div>
        </div>
      )}
      {dataSource.datasource_competitions && dataSource.datasource_competitions.length > 0 && (
        <>
          <div>
            <SelectInput
              formProps={{
                control: control,
                name: 'leagues',
                rules: {
                  validate: {
                    minLength: (values: ValueOption[]) =>
                      values.length > 0 || `Please select at least one competition.`,
                    maxLength: (values: ValueOption[]) =>
                      values.length <= 5 || `Currently it's possible to select at most 5 competitions.`
                  }
                }
              }}
              multiple
              label="Competitions"
              placeholder="Select competitions"
              searchable={true}
              options={leagueOptions}
              error={formState.errors.leagues}
            />

            <div className="mt-3 text-sm italic text-gray-400">
              Note: Your account may not have access to all of the listed competitions.
            </div>
          </div>
        </>
      )}
      {showSeasons && (
        <SelectInput
          formProps={{
            control: control,
            name: 'min_season'
          }}
          label="Start Season"
          placeholder="Select start season"
          options={seasonOptions}
        />
      )}

      <div>
        <div className={twJoin('flex gap-6', isMobile && 'flex-col')}>
          <SelectInput
            formProps={{
              control: control,
              name: 'refreshFrequency'
            }}
            searchable={false}
            placeholder="Default"
            label="Refresh Frequency"
            options={dataSourceRefreshFrequencyOptions}
          />
          <SelectInput
            formProps={{
              control: control,
              name: 'time'
            }}
            searchable={false}
            placeholder="Default"
            label="Time"
            options={CRONTimeOptions}
          />
        </div>

        <div className="mt-1 text-sm font-bold italic text-gray-600">
          Note: Changing the refresh frequency will change it for all data sources.
        </div>
        <div className="mt-3 text-sm italic text-gray-400">
          Note: Currently it's not possible to load data more frequently than weekly. All your datasources should have
          the iteration start setup at the same time.
        </div>
      </div>
    </form>
  );
};

export default memo(DatasourceConfigurationForm);
